import { CoatingObj } from './../../../configs/lensSettings.mock';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Frame } from '../../models/frame.model';
import { TranslateService } from '@ngx-translate/core';
import { TintsCoatingsService } from '../../services/tints-coatings.service';

@Component({
  selector: 'app-coating-host',
  templateUrl: './coating-host.component.html',
  styleUrls: ['./coating-host.component.scss']
})
export class CoatingHostComponent implements OnInit, OnChanges {

  @Input()
  public selectedFrame$: BehaviorSubject<Frame>;

  @Input()
  public coating: CoatingObj;

  @Input()
  public selectedCoating$: BehaviorSubject<string> = null;

  @Output()
  public onCoatingClick: EventEmitter<string> = new EventEmitter();

  public translationObjName: string = 'localization';
  public thumbnail;

  constructor(public _tintsCoatings: TintsCoatingsService, public translate: TranslateService) { }

  ngOnInit(): void {
    //Initialize here
  }

  ngOnChanges() {
    if (this.coating?.thumbnail) this.thumbnail = 'data:image/svg+xml;base64, ' + this.coating?.thumbnail;
  }

  selectCoating(catalogCode) {
    this.onCoatingClick.emit(catalogCode);
  }
}
