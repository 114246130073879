import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Routes, RouterModule } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { AuthGuard } from 'visauto-auth';
import { NoAvatarGuard } from './guards/no-avatar.guard';

import { VISVendorsModule } from 'visvendors';

import { UtilitiesModule } from 'utilities';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { DeleteDialogComponent } from './dialogs/delete/delete.dialog.component';
import { ECPConfirmOrderDialogComponent } from './dialogs/confirm/confirm.dialog.component';

import { ProfilePageComponent } from './pages/profile/profile.page.component';
import { ViewerPageComponent } from './pages/viewer/viewer.page.component';
import { OrderPageComponent } from './pages/order/order.page.component';
import { GalleryPageComponent } from './pages/frame-gallery/gallery.page.component';
import { ComparePageComponent } from './pages/compare/compare.page.component';
import { IsFavoritePipe } from './pipes/favorite.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ContactECPCardComponent } from './dialogs/contact-ecp-card/contact-ecp-card.component';
import { LoadingDotsComponent } from './components/loading-dots/loading-dots.component';
import { FrameSelectionDialogComponent } from './dialogs/frame-selection/frame-selection.dialog.component';

import { FrameColorDotComponent } from './components/frame-color-dot/frame-color-dot.component';
import { FrameHostComponent } from './components/frame-host/frame-host.component';
import { GalleryFrameHostComponent } from './components/gallery-frame-host/gallery-frame-host.component';
import { IntroDialogComponent } from './dialogs/intro/intro.dialog.component';
import { CoBrandingComponent } from '../components/cobranding/cobranding.component';
import { SocialDialogComponent } from './dialogs/social-dialog/social.dialog.component';
import { FrameGallerySearchComponent } from './components/frame-gallery-search/frame-gallery-search.components';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FilterTileComponent } from './components/filter-tile/filter-tile.component';
import { TruncateDirective } from './directives/truncate.directive';
import { FilterComponent } from './components/filter/filter.component';

import { SecureImageComponent } from '../handler/securedImageComponent';
import { ViewerStateGuard } from './guards/viewer.state.guard';
import { FeatureFlagDirective } from '../directives/featureFlag.directive';
import { VcldTooltipDirective } from '../directives/vcldTooltip.directive';
import { FormatPricePipe } from './pipes/format-price.pipe';
import { TintCoatingNamePipe } from './pipes/tint-coating-name.pipe';
import { CatalogNamePipe } from './pipes/catalog-name.pipe';
import { CoatingCatalogHostComponent } from './components/coating-catalog-host/coating-catalog-host.component';
import { CoatingHostComponent } from './components/coating-host/coating-host.component';
import { TintCatalogHostComponent } from './components/tint-catalog-host/tint-catalog-host.component';
import { TintHostComponent } from './components/tint-host/tint-host.component';
import { VcldSliderComponent } from '../directives/vcld-slider/vcld-slider.component';

const routes: Routes = [
    {
        path: 'profile',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        canDeactivate: [NoAvatarGuard],
        component: ProfilePageComponent,
    },

    {
        path: 'viewer/:sessionId',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        canDeactivate: [ViewerStateGuard],
        component: ViewerPageComponent,
    },

    {
        path: 'order/:sessionId/:frameId',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        component: OrderPageComponent,
    },

    {
        path: 'gallery/:sessionId',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        component: GalleryPageComponent,
    },

    {
        path: 'compare/:sessionId/:firstFrame/:secondFrame',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        component: ComparePageComponent,
    },
    { 
        path: '**', 
        redirectTo: 'landing',
    },
];

@NgModule({
    declarations: [
        // Pages
        ProfilePageComponent,
        ViewerPageComponent,
        OrderPageComponent,
        GalleryPageComponent,
        ComparePageComponent,
        // Components
        // FrameRecommendationComponent,
        SecureImageComponent,
        LoadingDotsComponent,
        FrameHostComponent,
        GalleryFrameHostComponent,
        CoBrandingComponent,
        FrameColorDotComponent,
        FrameGallerySearchComponent,
        FilterTileComponent,
        FilterComponent,
        // Dialogs
        DeleteDialogComponent,
        ECPConfirmOrderDialogComponent,
        ContactECPCardComponent,
        IntroDialogComponent,
        SocialDialogComponent,
        FrameSelectionDialogComponent,
        // Pipes
        IsFavoritePipe,
        FrameColorDotComponent,
        // Directives
        TruncateDirective,
        FeatureFlagDirective,
        VcldTooltipDirective,
        FormatPricePipe,
        TintCoatingNamePipe,
        CatalogNamePipe,
        CoatingCatalogHostComponent,
        CoatingHostComponent,
        TintCatalogHostComponent,
        TintHostComponent,
        VcldSliderComponent
    ],
    imports: [
        CommonModule,
        VISVendorsModule,
        // VISSupportModule,
        UtilitiesModule,
        NgxSliderModule,
        VirtualScrollerModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MatProgressSpinnerModule,
    ],
    exports: [CoBrandingComponent, FeatureFlagDirective, VcldTooltipDirective],
})
export class VtoModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
