import { Injectable } from '@angular/core';
import { ConsumerAppEnvironment as environment } from 'visenvironment';
import { DEBUG_FLAG, MOCK_FLAG } from '../configs/constant.flags';

export enum iOSVersions {
    iOS8_Up,
    iOS7,
    iOS6,
    iOS5,
    iOS4,
    iOS3_Below,
    NotIOS
}

export type ConsoleStyle = {
    headline: string;
    text: string;
    warning: string;
    success: string;
    error: string;
    bold: string;
    italic: string;
}

export enum LogLevel {
    ERROR,
    WARNING,
    SUCCESS,
    INFO
}

/**
 * Defines the css for the console.log command
 */
export const cs: ConsoleStyle = {
    headline: 'color: #C39BD3; font-weight: bold;',
    text: '',
    warning: 'color: #F4D03F; font-style: italic;',
    success: 'color: #58D68D; font-style: italic;',
    error: 'color: #EC7063; font-style: italic;',
    bold: 'font-weight: bold;',
    italic: 'font-style: italic'
}

@Injectable({
    providedIn: 'root'
})
export class DebugService {

    constructor() { 
                // Inject dependencies /set the default values for properties/ initialization code here. 
    
    }

    private isProd = environment.production;
    private debugFlag = localStorage.getItem(DEBUG_FLAG)?.toLowerCase() === 'true' && !this.isProd;

    public log(...data: any[]) {
        if (this.debugFlag) {
            console.log(...data);
        }
    }

    /**
     * Prints a styled message in the console
     * @param message the message to print
     * @param level severity of the message
     * @param data all additional data
     */
    public stuff(message: string, level: LogLevel = LogLevel.INFO,...data: any[]) {
        if (!this.debugFlag) return;

        switch (level) {
            case LogLevel.INFO:
                this.logInfo(message, ...data);
                break;
            case LogLevel.WARNING:
                this.logWarning(message, ...data);
                break;
            case LogLevel.SUCCESS:
                this.logSuccess(message, ...data);
                break;
            case LogLevel.ERROR:
                this.logError(message, ...data);
                break;
        }
    }

    private logInfo(message: string, ...data: any[]) {
        console.info(`%c[Debug] %c${message}`, cs.headline, cs.text, ...data);
    }

    private logError(message: string, ...data: any[]) {
        console.trace(`%c[Debug] %c${message}`, cs.headline, cs.error, ...data);
    }

    private logWarning(message: string, ...data: any[]) {
        console.log(`%c[Debug] %c${message}`, cs.headline, cs.warning, ...data);
    }

    private logSuccess(message: string, ...data: any[]) {
        console.log(`%c[Debug] %c${message}`, cs.headline, cs.success, ...data);
    }

    public get isDebugMode(): boolean {
        return this.debugFlag;
    }

    public get isMocked(): boolean {
        return localStorage.getItem(DEBUG_FLAG)?.toLowerCase() === 'true' && localStorage.getItem(MOCK_FLAG)?.toLowerCase() === 'true';
    }

    /**
     * Checks the navigator.platform & navigator.userAgent for iOS devices
     * @returns true if iOS device | false if not
     */
    public iOS_Device(): boolean {
        return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
            .includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }

    /**
     * Detects the iOS Version based on a feature detection lookup
     * @returns iOS Version based on feature detection between iOS3 and iOS8
     */
    public iOS_Version() {
        if (this.iOS_Device()) {
            if (window.indexedDB) return iOSVersions.iOS8_Up;
            if (window.SpeechSynthesisUtterance) return iOSVersions.iOS7;
            if (window.AudioContext) return iOSVersions.iOS6;
            if (window.matchMedia) return iOSVersions.iOS5;
            if (window.history && 'pushState' in window.history) return iOSVersions.iOS4;
            return iOSVersions.iOS3_Below;
        }

        return iOSVersions.NotIOS;
    }

    /**
     * Unsafe iOS detection based on a new Audio() Element Bug on iOS devices
     * @returns True if iOS device | false in other cases
     */
    public iOS_FallbackDetection() {
        let iosQuirkPresent = function () {
            let audio = new Audio();

            audio.volume = 0.5;
            return audio.volume === 1;   // volume cannot be changed from "1" on iOS 12 and below
        };

        let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
        let isAppleDevice = navigator.userAgent.includes('Macintosh');
        let isTouchScreen = navigator.maxTouchPoints >= 1;   // true for iOS 13 (and hopefully beyond)

        return isIOS || (isAppleDevice && (isTouchScreen || iosQuirkPresent()));
    }

    public isIosEdgeBrowser() {
        // UserAgent of iOS Edge Browser => EdgiOS
        return (navigator.userAgent.includes('EdgiOS'));
    }

}