<mat-drawer-container class="viewer" [hasBackdrop]="getViewerOptionsBackdrop()">
    <mat-drawer #viewerOptions class="row vieweroptions" 
    [mode]="getViewerOptionsMode()" position="end">
        <div class="loading" *ngIf="isLoading">
        </div>
        <mat-tab-group class="col s12 width-constraint viewer-tabs" mat-align-tabs="center" [selectedIndex]="selectedTabIndex" (focusChange)="saveScrollHeight()" (selectedTabChange)="setTabIndex($event)" [disablePagination]="false">
            <!-- favorited frames -->
            <mat-tab class="col s12">
                <ng-template mat-tab-label>
                    <div vcldAutomationId="consr_vis-viewer-page_Favorites_tab" (click)="trackTabFavorite()" style="width: 100%;" class="tab-label">
                        <p class="tab-text">
                            {{ "pages.viewer.tabs.favorites" | translate }}
                        </p>
                    </div>
                </ng-template>

                <div class="col s10 offset-s1">

                    <vcld-cobranding [session]="_session.selectedSession$ | async"
                        customStyle="position: absolute; top: 12px; right: 8.33%;" layout="">
                    </vcld-cobranding>

                    <div class="col s12 dmt-recommendations" *ngIf="(_frames.dmtRecommendedFramesCount$ | async) > 0">
                        <p class="tab-content-headline"
                            [style.marginTop]="((hasCoBranding$ | async) && isMobile) ? 'calc(32px + 12px + 12px)' : '12px'">
                            {{ 'pages.viewer.our_recommendations' | translate }}
                        </p>

                        <div class="col s12 m6 l6 xl6" *ngFor="let item of dmtFrames$ | async">
                            <vis-frame-host [frame]="item" [dmtFlag]="true" (onCompare)="switchToCompare($event)"
                                [priceFlag]="(_viewer.ecpSettings$ | async)?.pricingEnabled"
                                [modelNameFlag]="(_viewer.ecpSettings$ | async)?.modelNameEnabled"
                                [materialFlag]="(_viewer.ecpSettings$ | async)?.materialEnabled"
                                [selectedFrame$]="selectedFrame$" (click)="selectFrame(item)">
                            </vis-frame-host>
                        </div>
                    </div>

                    <p class="tab-content-headline" vcldAutomationId="consr_vto_viewer-page-component_your-favorites_hdr"
                        [style.marginTop]="((hasCoBranding$ | async) && isMobile) ? 'calc(32px + 12px + 12px)' : ''">
                        {{ 'pages.viewer.your_favourites' | translate : { favouritecount: favoriteCount$ | async } }}
                    </p>

                    <div class="gallery-link-container" *ngIf="(favoritedFrames$ | async)?.length > 0">
                        <button vcldAutomationId="consr_vis-viewer-page_view-fav-gallary_btn" mat-button class="secondary toggle-btn" (click)="switchToFavoriteGallery()">
                            <p vcldTooltip> {{ "pages.viewer.link_favorites" | translate }} </p>
                        </button>
                    </div>

                    <div class="col s12 m6" *ngFor="let item of favoritedFrames$ | async">
                        <vis-frame-host [frame]="item" (onCompare)="switchToCompare($event)"
                            [priceFlag]="(_viewer.ecpSettings$ | async)?.pricingEnabled"
                            [modelNameFlag]="(_viewer.ecpSettings$ | async)?.modelNameEnabled"
                            [materialFlag]="(_viewer.ecpSettings$ | async)?.materialEnabled" [selectedFrame$]="selectedFrame$"
                            (click)="selectFrame(item, true)" [tint]="selectedTint$" [coating]="selectedCoating$">
                        </vis-frame-host>
                    </div>

                    <p *ngIf="(favoritedFrames$ | async)?.length === 0"
                        class="col s12 quick-silver-text tab-content-headline"
                        style="font-weight: normal; margin-bottom: var(--margin-medium); font-size: var(--ui-text-font-size);"
                        [innerHTML]="'pages.viewer.no_favorites' | translate">
                    </p>

                    <!-- Footnote Recommended Retail Price -->
                    <div *ngIf="(_viewer.ecpSettings$ | async)?.pricingEnabled" class="rrp col s12">
                        <p class="quick-silver-text">* {{ "application.rrp" | translate }}</p>
                    </div>
                </div>
            </mat-tab>

            <!-- frame recommendations -->
            <mat-tab class="col s12">
                <ng-template mat-tab-label>
                    <div vcldAutomationId="consr_vis-viewer-page_frames_tab" (click)="trackTabReccomendations()" style="width: 100%;" class="tab-label">
                        <p class="tab-text" (click)="toggleFrameSlider(false)">
                            {{ "pages.viewer.tabs.frames" | translate }}
                        </p>
                    </div>
                </ng-template>

                <ng-content *ngIf="filterFocus; then filterBar else catalogueFrames"></ng-content>
                <ng-template #catalogueFrames>
                    <div class="col s10 offset-s1">
                        <div class="rec-headline">
                            <p class="tab-content-headline" vcldAutomationId="consr_vto_viewer-page-component_headline-recommendation_txt" style="height: fit-content;">{{ "pages.viewer.recommendations" | translate }}</p>
                        
                            <div vcldAutomationId="consr_vis-viewer-page_filter_btn" [ngClass]="{'no-filter': frameFilter.filterCount === 0}" class="filter-count" (click)="toggleFilterBar()"
                                matTooltip="{{ frameFilter.filterCount }} {{ 'tooltips.viewer.active_filters' | translate }}">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19.8" viewBox="0 0 22 19.8">
                                    <rect width="22" height="1.8" transform="translate(0 16)" />
                                    <rect width="22" height="1.8" transform="translate(0 9)" />
                                    <rect width="22" height="1.8" transform="translate(0 2)" />
                                    <rect width="2" height="5.8" transform="translate(16 7)" />
                                    <rect width="2" height="5.8" transform="translate(4 14)" />
                                    <rect width="2" height="5.8" transform="translate(9)" />
                                </svg>
                                <p *ngIf="frameFilter.filterCount > 0" class="cyan-text"> ({{ frameFilter.filterCount }})</p>
                            </div>
                        </div>

                        <div class="gallery-link-container">
                            <button vcldAutomationId="consr_vis-viewer-page_frame-gallery_btn" mat-button class="secondary toggle-btn" (click)="switchToOpticianGallery()">
                                <p vcldTooltip> {{ "pages.viewer.link_gallery" | translate }} </p>
                            </button>
                        </div>
                        <div class="col s12 m6 l6 xl6" *ngFor="let item of frames$ | async">
                            <vis-frame-host [frame]="item" (onCompare)="switchToCompare($event)" [selectedFrame$]="selectedFrame$"
                                [modelNameFlag]="(_viewer.ecpSettings$ | async)?.modelNameEnabled"
                                [materialFlag]="(_viewer.ecpSettings$ | async)?.materialEnabled"
                                [priceFlag]="(_viewer.ecpSettings$ | async)?.pricingEnabled" (click)="selectFrame(item)">
                            </vis-frame-host>
                        </div>

                        <div *ngIf="(frames$ | async)?.length === 0" class="col s12 frame-list">
                            <!-- font-size: 16pt -->
                            <p class="col s12 text" style="text-align: center;">
                                {{ "pages.viewer.no_frames" | translate }}
                            </p>
                        </div>
                        <!-- Footnote Recommended Retail Price -->
                        <div *ngIf="(_viewer.ecpSettings$ | async)?.pricingEnabled && frameArrayLength$?.value <= framesToPreload$?.value" class="rrp col s12">
                            <p class="quick-silver-text">* {{ "application.rrp" | translate }}</p>                          
                        </div>
                    </div>
                </ng-template>
                <ng-template #filterBar>
                    <vis-filter [sessionId]="sessionId" (isFilterbarClosed)="toggleFilterBar()"></vis-filter>
                </ng-template>
            </mat-tab>

            <!-- lenses -->
            <mat-tab class="col s12" *ngIf="showLenses">
                <ng-template mat-tab-label>
                    <div vcldAutomationId="consr_vis-viewer-page_lens_tab" (click)="trackTabLenses()" style="width: 100%;" class="tab-label">
                        <p class="tab-text">
                            {{ "pages.viewer.tabs.lenses" | translate }}
                        </p>
                    </div>
                </ng-template>

                <div>
                    <!-- CHIP LIST -->
                    <div class="col s10 offset-s1">
                        <mat-chip-listbox #chipList [aria-orientation]="'horizontal'">
                            <mat-chip-option *ngIf="selectedCoating" (removed)="resetCoating()">
                              {{ selectedCoating | tintCoatingName: translationObjName }}
                              <button matChipRemove> <mat-icon>cancel</mat-icon> </button>
                            </mat-chip-option>
                            <mat-chip-option *ngIf="selectedTint" (removed)="resetTint()" class="cyan-text">
                                {{_tintsCoatings.getTintUIName(selectedTint)}} {{_tintsCoatings.getTintUIValue(selectedTint)}}
                              <button *ngIf="!selectedCoating?.defaultTint" matChipRemove> <mat-icon>cancel</mat-icon> </button>
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </div>


                <div style="margin-top: 30px;" class="col s12">
                    <p class="col s5 offset-s1 subline" style="font-weight: bold;">
                        {{ 'pages.viewer.coating' | translate }}
                        <mat-icon class="coating-tint-info info-text-padding" matTooltip="{{ 'tooltips.viewer.coating-info' | translate }}">info</mat-icon>
                    </p>
                    <a style=" text-align: center; color: #0297f3; cursor: pointer;" (click)="resetCoating()"
                        class="col s5 offset-s1 text-ui" vcldAutomationId='consr_vto_viewer-page_reset-selection_link'>{{ 'pages.viewer.reset-selection' | translate }}</a>
                </div>

                <mat-divider style="margin-top: 1%; " class="col s10 offset-s1"></mat-divider>
                <app-coating-catalog-host [selectedFrame$]="selectedFrame$" [selectedCoating$]="selectedCoating$" (onCoatingClick)="selectCoating($event)"></app-coating-catalog-host>

                <!-- PART 2-->
                <div class="col s12" id="tint-container" [ngStyle]="{'padding-bottom': sunSliderFocus ? '80px' : ''}">
                    <div style="margin-top: 30px;">
                        <!-- font-size: 14pt; -->
                        <p [ngClass]="selectedCoating?.defaultTint ? 's10' : 's6'" class="col offset-s1 subline" style=" font-weight: bold; ">
                            {{ 'pages.viewer.tint' | translate }}
                            <mat-icon class="coating-tint-info info-text-padding" *ngIf="selectedCoating?.defaultTint" matTooltip="{{ 'tooltips.viewer.tint-info' | translate }}">info</mat-icon>
                        </p>
                        <!-- font-size: 10pt; -->
                        <a style=" text-align: center;  color: #0297f3; cursor: pointer;" *ngIf="!selectedCoating?.defaultTint" (click)="resetTint()"
                            class="col s5 text-ui" vcldAutomationId="consr_vto_viewer-page_reset-selection_link">{{ 'pages.viewer.reset-selection' | translate }}</a>
                    </div>

                    <mat-divider style="margin-top: 1%; " class="col s10 offset-s1"></mat-divider>
                    <app-tint-catalog-host [selectedFrame$]="selectedFrame$" [selectedCoating$]="selectedCoating$" [selectedTint$]="selectedTint$" (onTintClick)="selectTints($event)"></app-tint-catalog-host>
                </div>
            </mat-tab>
        </mat-tab-group>
    </mat-drawer>

    <mat-drawer-content class="viewer">
        <div class="loading" *ngIf="isLoading">
            <div class="gradient" *ngIf="initalLoading">
                <vcld-loading-dots [showText]="initalLoading"></vcld-loading-dots>
            </div>

            <vcld-loading-dots *ngIf="!initalLoading" [showText]="initalLoading"></vcld-loading-dots>
        </div>

        <div class="frame-info-wrapper" vcldAutomationId="consr_vto_vis-viewer-page_frame-info-in-viewer_details" *ngIf="selectedFrame$ | async as frame">
            <p style="font-weight: bold">{{ frame?.brand || "" }}</p>
            <p *ngIf="(_viewer.ecpSettings$ | async)?.modelNameEnabled"> {{ frame?.model || "" }}</p>
            <p *ngIf="selectedCoating" class="selected-lenses">
                {{ selectedCoating | tintCoatingName: translationObjName }}
            </p>
            <p *ngIf="selectedTint" class="selected-lenses">
                 {{_tintsCoatings.getTintUIName(selectedTint)}} {{_tintsCoatings.getTintUIValue(selectedTint)}}</p>
            <div class="row disabled" *ngIf="!isFrameAvailable">
                <mat-icon class="col s1">info</mat-icon>
                <span class=" col s11" *ngIf="isFramePermanentlyUnavailable">
                    {{ "pages.gallery.permanently" | translate }}
                </span>
                <span class="col s11" *ngIf="isFrameTemporaryUnavailable && !frame.availableAgainFrom">
                    {{ "pages.gallery.currently" | translate }}
                </span>
                <span class="col s11" *ngIf="frame.availableAgainFrom">
                    {{ "pages.gallery.available" | translate }}: {{frame.availableAgainFrom}}
                </span>
            </div>
        </div>

        <div class="hideable close-btn">
            <button vcldAutomationId="consr_vis-viewer-page_back-to-previous_btn" mat-fab class="circle transparent" (click)="closeViewer()">
                <mat-icon>arrow_back_ios_new</mat-icon>
            </button>
        </div>

        <div class="rotation-icon">
            <span class="mat-button-wrapper" matTooltip="{{ (isAvatarVisible() ? 'tooltips.viewer.rotation-3d-icon' : 'tooltips.viewer.rotation-3d-frame') | translate }}">
                <i class="icon rotation-3d-black"></i>
            </span>            
        </div>

        <div class="side-button">
            <button vcldAutomationId="consr_vis-viewer-page_minimise-options_btn" class="trapezoid" (click)="viewerOptionsToggle()">
                <mat-icon *ngIf="!viewerOptions.opened">chevron_left</mat-icon>
                <mat-icon *ngIf="viewerOptions.opened">chevron_right</mat-icon>
            </button>
        </div>

        <div id="viewer-wrapper"></div>

        <div class="gallery-wrapper hideable hide-on-landscape">
            <button vcldAutomationId="consr_vis-viewer-page_to-optician-inventory_btn" mat-fab class="blured circle gallery-btn-color" (click)="switchToGallery()"
                matTooltip="{{ 'tooltips.viewer.gallery' | translate }}">
                <mat-icon>import_contacts</mat-icon>
            </button>
        </div>

        <div class="hideable order-btn hide-on-landscape">
            <button vcldAutomationId="consr_vis-viewer-page_share-frame_btn" mat-flat-button color="primary" class="rounded" [disabled]="((selectedFrame$ | async) === null) || (!isFrameAvailable)" 
                  (click)="switchToOrder()">
                {{ "pages.viewer.select_frame" | translate }}
                <i class="icon visucloud-icons next"></i>
            </button>
        </div>

        <div class="hideable order-btn-mobile only-mobile">
            <button vcldAutomationId="consr_vis-viewer-page_share-frame_btn" mat-flat-button color="primary" [disabled]="((selectedFrame$ | async) === null) || (!isFrameAvailable)"
                class="rounded" (click)="switchToOrder()">
                {{ "pages.viewer.select_frame_mobile" | translate }}
                <i class="icon visucloud-icons next"></i>
            </button>
        </div>

        <div class="hideable avatar-toggle-btn">
            <button vcldAutomationId="consr_vis-viewer-frame-only_toggle"  mat-button disableRipple="true" class="vis-dis" [disabled]="((selectedFrame$ | async) === null) || frameSizeError"
                (click)="toggleAvatarVisibility()">
                <p>{{ "pages.viewer.frame_only" | translate }}</p>
                <mat-icon style="margin-left: var(--margin-tiny);">{{
                    isAvatarVisible() ? "toggle_off" : "toggle_on"
                    }}</mat-icon>
            </button>
        </div>
        <mat-menu [class]="'nose_ud_menu'" hasBackdrop="true" [backdropClass]="'nose_ud_menu-back'" #sliderMenu="matMenu">
            <div class="slider-wrapper position-slider" (click)="$event.stopPropagation()">
                <div class="frame-icon-wrapper">
                    <mat-icon>keyboard_arrow_up</mat-icon>
                    <i class="icon visucloud-icons frame-square"></i>
                </div>


                <app-vcld-slider [customClasess]="'custom-slider'" (click)="$event.stopPropagation()" [options]="verticalSliderOptions" [value]="sliderValueVertical" [direction]="'vertical'" (userValueChange)="onInputChange($event)" (valueChange)="onValueChange($event)"></app-vcld-slider>

                <div class="frame-icon-wrapper">
                    <i class="icon visucloud-icons frame-square"></i>
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
            </div>

            <div class="slider-wrapper rotation-slider" (click)="$event.stopPropagation()">
                <div class="frame-icon-wrapper">
                    <mat-icon>undo</mat-icon>
                    <i class="icon visucloud-icons frame-square"></i>
                </div>

                <app-vcld-slider  class="vertical-slider" (click)="$event.stopPropagation()" [customClasess]="'custom-slider'" [options]="horizontalSliderOptions" [value]="sliderValueHorizontal" [direction]="'horizontal'" (valueChange)="onValueChange($event)" (userValueChange)="onInputChange($event)"></app-vcld-slider>
                <div class="frame-icon-wrapper">
                    <mat-icon>redo</mat-icon>
                    <i class="icon visucloud-icons frame-square"></i>
                   
                </div>
            </div>
        </mat-menu>

        <mat-menu #colorMenu="matMenu">
            <mat-action-list style="padding-top: 0px; padding-bottom: 0px;" class="color-dots">
                <button vcldAutomationId="consr_vis-viewer-page_options-color-btn" mat-list-item style="margin: 0px;" *ngFor="let color of (frameVariants$ | async)?.colors || []"
                    (click)="selectFrameVariantById(color.frameId)">
                    <vis-frame-color-dot [frameId]="color.frameId" [layoutcss]="'menu-circle'">
                    </vis-frame-color-dot>
                    <span vcldTooltip [ngClass]="(color.frameId === (selectedFrame$ | async).id) ? 'bold' : ''">{{color.color}}</span>
                </button>
            </mat-action-list>
        </mat-menu>

        <mat-menu #sizeMenu="matMenu">
            <mat-action-list style="padding-top: 0px; padding-bottom: 0px;">
                <button vcldAutomationId="consr_vis-viewer-page_options-size-btn" *ngFor="let size of (frameVariants$ | async)?.sizes || []"
                    (click)="selectFrameVariantById(size.frameId)" class="item-content" mat-list-item style="margin: 0px"
                    matTooltip="{{ 'tooltips.viewer.size_explanation' | translate }}"
                    [matTooltipDisabled]="isStandardSize(size.size)"
                    [ngClass]="(size.frameId === (selectedFrame$ | async).id) ? 'bold' : ''">
                    {{size.size}}
                </button>
            </mat-action-list>
        </mat-menu>


        <div class="footer-bar white-smoke">
            

                <button vcldAutomationId="consr_vis-viewer-page_color-options_btn" [matMenuTriggerFor]="colorMenu" (click)="trackColorMenu()" (mouseenter)="adjustTooltip()" class="vis-dis"
                     [disabled]="(selectedFrame$ | async) === null"
                    [matTooltipDisabled]="hideColorMenuTooltip"
                    mat-icon-button matTooltip="{{ 'tooltips.viewer.color_options' | translate }}">

                    <div *ngIf="(selectedFrame$ | async)">
                        <vis-frame-color-dot [frameId]="(selectedFrame$ | async).id" imagecss="color-dot-image">
                        </vis-frame-color-dot>
                    </div>

                    <div *ngIf="(selectedFrame$ | async) === null" >
                        <span class="outer-circle">
                            <span class="inner-circle"></span>
                        </span>
                    </div>
                </button>

                <button vcldAutomationId="consr_vis-viewer-page_size-options_btn" class="vis-dis" [disabled]="(selectedFrame$ | async) === null" mat-icon-button
                     [matMenuTriggerFor]="sizeMenu"
                    (click)="trackSizeMenu()" (mouseenter)="adjustTooltip();"
                    [matTooltipDisabled]="hideSizeMenuTooltip"
                    matTooltip="{{ 'tooltips.viewer.size_options' | translate }}">
                    <mat-icon>straighten</mat-icon>
                </button>

                <button vcldAutomationId="consr_vis-viewer-page_frame-adjust_btn" class="vis-dis" (click)="trackNoseSlider()"
                    [disabled]="(selectedFrame$ | async) === null || !isAvatarVisible() || frameSizeError" mat-icon-button
                    [matMenuTriggerFor]="sliderMenu" matTooltip="{{ 'tooltips.viewer.nose_slider' | translate }}">
                    <i class="icon visucloud-icons nose-slider"></i>
                </button>

                <button vcldAutomationId="consr_vis-viewer-page_viewer-fav-unfav_btn" mat-icon-button [disabled]="(selectedFrame$ | async) === null" class="vis-dis" (click)="favoriteSelectedFrame()" matTooltip="{{ 
                        (isFavorite$ | async) ?
                        ('tooltips.viewer.fav_filled' | translate) :
                        ('tooltips.viewer.fav_unfilled' | translate)
                    }}">
                    <mat-icon>{{
                        (isFavorite$ | async) ? "favorite" : "favorite_border"
                        }}</mat-icon>
                </button>

                <button vcldAutomationId="consr_vis-viewer-page_share-options_btn" *ngIf="!isEdgeiOS" [disabled]="(selectedFrame$ | async) === null || !(isFullyLoaded$ | async)"
                    class="col vis-dis" (click)="shareFrame()" mat-icon-button
                    matTooltip="{{'tooltips.viewer.share' | translate}}">
                    <mat-icon>share</mat-icon>
                </button>
        </div>


    </mat-drawer-content>
</mat-drawer-container>

<!-- Filter-Controls (Sticked to bottom of Frame-Slider) -->
<div *ngIf="filterFocus" class="col s12 white sticky" [style.opacity]="viewerOptions.opened ? '1' : '0'">
    <div *ngIf="viewerOptions.opened && (selectedTabIndex===1)" class="filter-btns">
        <button mat-button color="primary" vcldAutomationId="consr_vto_vis-viewer-page_clear_btn" (click)="clearFilters()"> {{ "pages.gallery.filters.clear" | translate
            }}</button>
        <button mat-flat-button color="primary" vcldAutomationId="consr_vto_vis-viewer-page_show_btn" (click)="applyFilters()">{{"pages.gallery.filters.showfilteredframes" |
            translate}}({{ (filteredFramesCount$ | async) || 0 }})</button>
    </div>
</div>

<!-- Daylight Simulator (Sticked to bottom of Frame-Slider) -->
<div *ngIf="sunSliderFocus" class="col s12 white sticky" [style.opacity]="viewerOptions.opened ? '1' : '0'">
    <div *ngIf="viewerOptions.opened && (selectedTabIndex===2)" class="daylight-simulator">
        <p class="bold daylight-heading">{{ 'pages.viewer.daylight_simulator' | translate }}</p>
        <div class="daylight-controls">
            <div class="icon-wrapper">
                <i class="visucloud-icons cloud"></i>
            </div>
            <input type="range" class="sunslider" [(ngModel)]="sliderValue" (input)="onSunSliderInputChange($event)" #sunslider />
            <div class="icon-wrapper">
                <i class="visucloud-icons sun"></i>
            </div>
        </div>
    </div>
</div>