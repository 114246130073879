import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { DebugService } from '../../services/debug.service';
import { AvatarCreationSession } from '../../vto/models/avatarcreationsession';
import { DataService,BinaryType } from '../../vto/services/data.service';

@Component({
    selector: 'vcld-cobranding',
    templateUrl: './cobranding.component.html',
    styleUrls: ['./cobranding.component.scss']
})
export class CoBrandingComponent implements OnInit, OnChanges{

    public hsMargin = 0;
    public intervallId;
    public needLogo: boolean = true;
    public processFlag = true;

    @Input()
    public session: AvatarCreationSession;

    private defaultStyle: string = " ";


    @Input()
    public layout: string = '';

    @Input()
    public set customStyle(value: string) {
        this.defaultStyle += value;
    }

    public get customStyle() {
        return this.defaultStyle;
    }

    public ecpLogo$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(private _datacache: DataService, private debug: DebugService) { }

    public async loadEcpLogo() {
        if (this.session) {
            const b = await this._datacache.getBinary(BinaryType.ECP_LOGO,this.session.opticianId,true);
            if (b) {
                const x = b;
                this.ecpLogo$.next(x);
            } else {
                this.needLogo = false;
            }
        }
    }

    async ngOnInit() {
        if (this.session) {
            this.loadEcpLogo();
        }

        this.updateEcpLogo();
    }

    updateEcpLogo() {
        this.intervallId = requestAnimationFrame(() => {
            this.updateEcpLogo()
        });

        const hsLogo = document.getElementById('hsLogo') as HTMLImageElement;
        const height = hsLogo?.height;
        if (height && height < 32 && height > 1) {
            this.hsMargin = Math.floor((32 - height) / 2) - 1;
            this.cancelRequestProcessing(this.intervallId);
        } else if (height == 32 || !this.needLogo) {
            this.cancelRequestProcessing(this.intervallId);
        } else if (hsLogo?.src != null && hsLogo?.src != "") {
            this.cancelRequestProcessing(this.intervallId);
        }
        this.debug.log('Run: ', height, this.hsMargin);
    }

    cancelRequestProcessing(intervallId) {
        this.processFlag = false;
        cancelAnimationFrame(intervallId);
    }

    ngOnChanges(changes) {
        this.debug.log(changes);
        if (changes['session']) {
            this.loadEcpLogo();
        }
    }


}
