import { FavoriteFrame, AvatarCreationSession, AvatarSource } from './../../models/avatarcreationsession';
import {Component, OnInit, AfterViewInit, ViewChild, ElementRef, NgZone, Renderer2, ErrorHandler } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute } from "@angular/router";
import { of, Observable, BehaviorSubject, combineLatest, from, Subscription, fromEvent } from "rxjs";
import { ViewerFactoryService } from "../../services/viewer.factory.service";
import { VTO, GlassesTransformation, AvatarEndpoints, GlassesEndpoints } from "@vision/webviewer/lib/vto";
import { MatSlider, MatSliderChange } from "@angular/material/slider";
import { map, tap, shareReplay, startWith, mergeMap, take, finalize, first, filter, mergeAll, skipWhile,debounceTime,distinctUntilChanged } from "rxjs/operators";
import { Frame, FrameAvailability } from "../../models/frame.model";
import { CoatingObj, TintObj } from "../../../configs/lensSettings.mock";
import { MatSidenav } from "@angular/material/sidenav";
import { LensSettings } from "@vision/webviewer/lib/widget";
import { FrameVariants } from "../../models/frame.variants.model";
import { ApplicationInsightsService } from "../../../services/applicationInsights.Service";
import { CameraView } from "@vision/webviewer/lib/vto";
import { GoogleTagManagerService, GTMCustomEvents } from "../../services/gtm.service";
import { MatDialog } from "@angular/material/dialog";
import { SocialDialogComponent } from "../../dialogs/social-dialog/social.dialog.component";
import { DebugService } from "../../../services/debug.service";
import { FrameFilterService } from "../../services/frame-filter.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { GLASS_TYPE_ELIGIBILITY } from "../../../configs/constant.flags";
import { InteractionType, StatisticService, TintCoatingInteraction } from '../../../services/statistic.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { FeatureFlagsService } from '../../../services/featureFlag.service';
import canvasTxt from 'canvas-txt';
import { SessionService } from '../../services/session.service';
import { TintsCoatingsService } from '../../services/tints-coatings.service';
import { FrameService } from '../../services/frame.service';
import { ViewerService } from '../../services/viewer.service';
import { DataService,BinaryType } from '../../services/data.service';
import {ChangeContext, Options as SliderOptions} from '@angular-slider/ngx-slider';
import { ScrollService } from '../../services/scroll.service';
import { SnackbarComponent } from '../../../components/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "vis-viewer-page",
    templateUrl: "./viewer.page.component.html",
    styleUrls: ["./viewer.page.component.scss"],
})
export class ViewerPageComponent extends VTO implements OnInit, AfterViewInit {
    @ViewChild("viewerOptions")
    public viewerOptions: MatSidenav;

    @ViewChild("sunslider")
    public sunSlider: MatSlider | HTMLInputElement;

    constructor(
        private appInsight: ApplicationInsightsService,
        private gtm: GoogleTagManagerService,
        public translate: TranslateService,
        private router: Router,
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private viewerFactory: ViewerFactoryService,
        private zone: NgZone,
        private debug: DebugService,
        public frameFilter: FrameFilterService,
        private stats: StatisticService, 
        private auth: OAuthService,
        private features: FeatureFlagsService,
        public _session: SessionService,
        public _tintsCoatings: TintsCoatingsService,
        public _frames: FrameService,
        public _viewer: ViewerService,
        private _datacache:DataService,
        private renderer:Renderer2,
        private readonly _errorHandler: ErrorHandler,  
        private _scrollService: ScrollService,
        private readonly snackbar: MatSnackBar
    ) {
        super();
    }

    public verticalSliderOptions: SliderOptions = {
        step: 1,
        vertical: true,
        showTicks: false,
        showTicksValues: false,
        ceil: 19,
        floor: 0,
        hidePointerLabels: true,
        hideLimitLabels: true,
        rightToLeft: true
    }

    public horizontalSliderOptions: SliderOptions = {
        step: 1,
        vertical: false,
        showTicks: false,
        showTicksValues: false,
        ceil: 9,
        floor: 0,
        hidePointerLabels: true,
        hideLimitLabels: true,
    }

    public isFrameAvailable: boolean = true;

    public isFrameTemporaryUnavailable: boolean;

    public isFramePermanentlyUnavailable: boolean;

    public fromQueryFlag: string;

    public frameListStart = 0;

    public fullsreenIcon = "open_in_full";

    // Determins which FrameSlider should be used
    // 0 = Favorite Slider
    // 1 = Optician Slider
    public frameSliderIndex: 0 | 1 = 1;

    public sliderValueVertical = 1;
    public sliderValueHorizontal = 1;

    private sliderTransformation;

    public favoritesSelected: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    public isDebugMode = false;
    public isLoading = true;
    public isPaused: boolean = false;

    @ViewChild("frameWrapper")
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    private _frameWrapperHost: ElementRef;
    private _frameWrapperContainer: HTMLDivElement;


    // Scroll index indicating when the next row of frames should be preloaded
    public scrollPin = 1000;

    // Threshold for the scroll preloader (means: scrollindex is between 0 and threshold)
    public scrollPinThreshold = 150;

    public sessionId: string;

    // Amount of Frames shown in the Slider
    private frameAmount = 200;

    // Holds the currently applied lensSettings (shader of the lens)
    public currentLensSettings: LensSettings;
    // public currentLensSettingsId: string = null;
    // public currentTintId: string;
    public currentLensSettingsCatalogCode: string = null;
    public currentTintCatalogCode: string;
    public selectedCoating: CoatingObj = null;
    public selectedTint: TintObj = null;

    public compareList: Array<string> = [];
    public compareLensesList: Array<any> = [];

    public switchableFrames$: Observable<Frame[]>;

    public allFrames$: Observable<Frame[]>;
    public favoritedFrames$: Observable<Frame[]>;
    public favoritedFrameObjects$: Observable<FavoriteFrame[]>;
    public frameVariants$: Observable<FrameVariants>;

    public frameCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(
        0
    );
    public availableFrames$: BehaviorSubject<number> =
        new BehaviorSubject<number>(0);

    public canIncrease$: Observable<boolean>;
    public canDecrease$: Observable<boolean>;

    public selectedFrame$: BehaviorSubject<Frame> = new BehaviorSubject<Frame>(
        null
    );

    public isFullyLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    public selectedTint$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public selectedCoating$: BehaviorSubject<string> = new BehaviorSubject<string>(null);


    public isFavorite$: Observable<boolean>;

    public favoriteCount$: Observable<number>;

    public hasCoBranding$: Observable<boolean>;

    public dmtFrames$: Observable<Frame[]>;

    public dmtFramesCount$: Observable<number>;
    
    public initalLoading: boolean = true;

    public selectedTabIndex: number = 1;

    public scrollDepth: number[] = [0, 0, 0];
    public scrollContainer: Element;

    public sunSliderFocus: boolean = false;
    public filterFocus: boolean = false;
    public filteredFramesCount$: Observable<number>;
    public showLenses: boolean = false;
    public translationObjName: string = 'localization';
    public tintIdPrefix: string = 'tint_';
    private duravisionPlatinumId: string;
    private pageSource: string = 'viewer';
    private favoriteMode: string = 'favorite';
    private opticianMode: string = 'optician';
    public hideSizeMenuTooltip: boolean = false;
    public hideColorMenuTooltip: boolean = false;
    public sliderValue = 0;
    private avatarAssets = null;

    private sub;
    private viewerScroll$: Observable<any>;
    private viewerScrollSubscription: Subscription;
    private viewerScrollDebounceTime = 150;
    private lastScrollReloadIndex: number = 0;
    // Number of Frames to fetch
    public preloadFrames = 14;

    // Number of frames to preload on scroll agent
    private preloadIncrement = 10;
    public framesToPreload$: BehaviorSubject<number> =
    new BehaviorSubject<number>(this.preloadFrames);
    public frameArrayLength$: BehaviorSubject<number> = new BehaviorSubject(0);
    public frames$: Observable<Frame[]>;

    public frameSizeError: boolean = false;

    public ngAfterViewInit() {
        if (this._frameWrapperHost) {
            this._frameWrapperContainer = this._frameWrapperHost.nativeElement;
        }
        if (this.route.snapshot.queryParams.from == "gallery") 
           this.scrollContainer = document.getElementsByClassName("mat-drawer-inner-container")[1];        
        if(!this.scrollContainer)  this.scrollContainer = document.getElementsByClassName("mat-drawer-inner-container")[0];
        this.viewerScroll$ = fromEvent<Event>(this.scrollContainer, "scroll");
        this.viewerScrollSubscription = this.viewerScroll$
            .pipe(
                tap((e) => { this._scrollService.scrollDispatcher$.next(e) }),
                debounceTime(this.viewerScrollDebounceTime)
            ).subscribe((scrollEvent: Event) => {
                const currentIndex = this.getScrollY(scrollEvent);
                if (currentIndex >= this.lastScrollReloadIndex) {
                    this.lastScrollReloadIndex = currentIndex;
                    this.loadMore();
                }
            });
    }  

    public async ngOnInit() {
        this.isIOSDevice = this.isIOS || this.debug.iOS_Device();

        const tncTask = this._tintsCoatings.getTintsAndCoatings();
        this.showLenses = true;

        const queryFrameId = this.route.snapshot.queryParams.frameId;

        this.sessionId = this.route.snapshot.params.sessionId;

        this.avatarAssets = this.getAvatarEndpoints();

        this.fromQueryFlag = this.route.snapshot.queryParams.from;
        const sessionsTask = this._session.fetchSessionsList();
        const parallelTasks = [await sessionsTask, await tncTask];

        this.duravisionPlatinumId = this._tintsCoatings.getDefaultCoatingId('Platinum');

        try {
            await this._session.setSession(this.sessionId);
        } catch (error) {
            this.router.navigate(['/profile']);
            return;
        }

        await this._viewer.initEcpSettings();
        await this._frames.initAllFrames(this._session.selectedSession$.getValue().opticianCustomerNumber);
        await this.frameFilter.initFilters(this.sessionId);

        const initRecommendations = this._frames.initRecommendedFrames(this.sessionId);
        const initCampaignRcommendations =  this._frames.initCampaignRecommendations();
        const initFavoritedFrames = this._frames.initFavoritedFrames();
        const result = [await initRecommendations, await initCampaignRcommendations, await initFavoritedFrames];

        if (!queryFrameId) {
            this.initializeViewerWithoutFrame();
        }
        
        this.appInsight.logPageView("viewer", window.location.href);

        this.allFrames$ = combineLatest([
            this._frames.frsSortedFrameCatalogue$,
            this.frameFilter.filters$,
            this.frameCounter$,
            this.selectedFrame$,
        ]).pipe(
            tap(([frames, _cnt, frame]) => {
                this.debug.log("All Frames emitted with ", frames);
                this.availableFrames$.next(frames.length);
            }),
            map(([frames, filters, cnt]) =>
                this.frameFilter.filterFrames(filters, frames)
                    .filter(
                        (f) =>
                            f.availabilityStatus ? parseInt(FrameAvailability[f.availabilityStatus]) == FrameAvailability.AVAILABLE : false
                    )
                    .slice(cnt, cnt + this.frameAmount)
            )           
        );

        this.frames$ = this.getFrames().pipe();

        this.filteredFramesCount$ = combineLatest([
            this._frames.frsSortedFrameCatalogue$,
            this.frameFilter.filtersForPreview$,
        ]).pipe(
            map(
                ([frames, filters]) => this.frameFilter.filterFrames(filters,frames).length
            )
        );

        this.getFavFrames();

        // this.favoritedFrameObjects$ = this._session.selectedSession$.pipe(
        //     map((session) => session.favoritedFrames),
        //     startWith<FavoriteFrame[]>([])
        // );

        this.favoriteCount$ = combineLatest([
            this._session.selectedSession$,
            this._frames.allFrames$,
        ]).pipe(
            map(([session, frames]) => {
                return session.favoritedFrames ? session.favoritedFrames.length : 0;
            })
        );

        this.isFavorite$ = this.isFrameFavorite();

        this.hasCoBranding$ = this._session.selectedSession$.pipe(
            map((session) => {
                if (!session) return;

                return from(
                    this._datacache.getBinary(                       
                        BinaryType.ECP_LOGO,
                        session.opticianId,
                        true
                    )
                );
            }),
            mergeMap((logo) => logo),
            map((s) => s != null && s != undefined)
        );

        this.frameVariants$ = this.selectedFrame$.pipe(
            tap((_) =>
                this.debug.log("Selected Frame Observable changed: ", _)
            ), // Debugging
            map((f) =>
                f
                    ? this._frames.getVariantsForFrame(f.id, this._session.selectedSession$.getValue()?.opticianCustomerNumber)
                    : of({ colors: [], sizes: [] })
            ), // Map all Frames to Variants
            mergeAll(), // Get all Metadata
            tap((_) => this.debug.log("Result of Variants Merge: ", _)),
            shareReplay(1)
        );


        this.sub = combineLatest([this._frames.favoritedFrames$, this._frames.dmtRecommendedFrames$]).pipe(
            skipWhile(([frames, dmt]) => frames.length == 0 && dmt.length === 0),
            tap(([frames, dmtFrames]) => {
                this.initInitialLoading(frames, dmtFrames, queryFrameId);
            }),
            finalize(() => { this.sub.unsubscribe(); })
        ).subscribe();

         if(queryFrameId) {
            if(this.route.snapshot.queryParams.coating) {
                this.selectedCoating = this._tintsCoatings.getLensByCatalogCode(this.route.snapshot.queryParams.coating);
            }


            const subscriber = combineLatest([
                this.route.queryParams,
                this._frames.frsSortedFrameCatalogue$,
            ])
                .pipe(
                    map(([params, frames]) => {
                        const frameId = params.frameId;
                        const selectedFrame = frames.filter(
                            (f) => f.id === frameId
                        );
                        return selectedFrame.length > 0
                            ? selectedFrame[0]
                            : null;
                    }),
                    filter((f) => f != null),
                    tap((frame) => {
                        let frameCopy = Object.assign({}, frame);
                        this.selectedFrame$.next(frameCopy);

                        if(this.route.snapshot.queryParams.coating) {
                            this.selectCoating(this.route.snapshot.queryParams.coating);
                            frameCopy.coating = this.selectedCoating;
                        }

                        if(this.route.snapshot.queryParams.tint) {
                            this.selectTints(this.route.snapshot.queryParams.tint);
                            frameCopy.tint = this.selectedTint;
                        }
                        this.initializeViewerWithFrame(frame.id);
                    }),
                    take(1),
                    finalize(() => subscriber.unsubscribe())
                )
                .subscribe();
        }

        this.gtm.pageView("viewer");

        this.dmtFrames$ = this._frames.dmtRecommendedFrames$.pipe(
            map((fs) =>
                fs.filter(
                    (f) =>
                        f.availabilityStatus ? parseInt(FrameAvailability[f.availabilityStatus]) != FrameAvailability.PERMANENTLY_NOT_AVAILABLE : true
                )
            )
        );
        setTimeout(() => this.isFullyLoaded$.next(true), 100);
    }
    public getFavFrames()
    {
        this.favoritedFrames$ = combineLatest([
            this._frames.favoritedFrames$,
            this.frameCounter$,
            this.selectedFrame$,
        ]).pipe(
            tap(([frames, cnt, frame]) => {
                this.availableFrames$.next(
                    frames.filter(
                        (f) =>
                            f.available ||
                            f.available === null ||
                            f.available === undefined
                    ).length
                );
            }),
            map(([frames, cnt, frame]) =>
                frames
                    .filter(
                        (f) =>
                            f.available ||
                            f.available === null ||
                            f.available === undefined
                    )
                    .slice(cnt, cnt + this.frameAmount)
            ),
            tap((frames) =>
                this.debug.log(
                    `%c[fravoritedFrames$] %cCount of favorites is %c${frames.length}`,
                    "color: black; font-weight: bold;",
                    "",
                    "color: green;",
                    frames
                )
            )
        );

    }
    isFrameFavorite(): Observable<boolean> {
        return combineLatest([
            this.selectedFrame$,
            this._session.selectedSession$,
            this.selectedTint$,
            this.selectedCoating$
        ]).pipe(
            tap(([frame, session, tintCatalogCode, coatingCatalogCode]) => this.debug.log(
                "%c[isFavorite$] %c",
                "color: black; font-weight: bold;",
                "",
                frame,
                session,
                frame ? session.favoritedFrames.findIndex(x => x.frameId === frame.id && x.coatingId == coatingCatalogCode && x.tintId == tintCatalogCode) > -1 : false
            )
            ),
            map(([frame, session, tintCatalogCode, coatingCatalogCode]) => {
                if (!this.features.isFeatureFlagEnabled('CameronTCEnabled') && this._session.selectedSession$.getValue().source === AvatarSource.CAMERON)
                    return frame ? session.favoritedFrames.findIndex(x => x.frameId === frame.id) > -1 : false;

                else
                    return frame ? session.favoritedFrames.findIndex(x => x.frameId === frame.id && x.coatingId == coatingCatalogCode && x.tintId == tintCatalogCode) > -1 : false;

            }),
            startWith(false)
        );
    }
    initInitialLoading(frames, dmtFrames, queryFrameId) {
        if (this.initalLoading) {
            const frameFirstTab = (frames?.findIndex(e => e.id === queryFrameId) !== -1) || 
                                  (dmtFrames?.findIndex(e => e.id === queryFrameId) !== -1);
            if (frameFirstTab) {
                this.selectedTabIndex = 0;
            } else if (!queryFrameId) {
                this.selectedTabIndex = dmtFrames?.length > 0 || frames?.length > 0 ? 0 : 1;
            }
        }
    }
    public getFrames() {
        return combineLatest([this.allFrames$, this.framesToPreload$.pipe(distinctUntilChanged())]).pipe(
            map(([frames, framesToPreload]) => {                    
                this.frameArrayLength$.next(frames.length);           
                return frames.slice(0, framesToPreload);;                
            }),
        );
    }

    private getScrollY(scrollEvent: Event): number {
        return (scrollEvent.target as Element).scrollTop;
    }

    public loadMore() {
        this.framesToPreload$.next(
            this.preloadFrames + this.preloadIncrement <
                this.frameArrayLength$.getValue()
                ? this.framesToPreload$.getValue() + this.preloadIncrement
                : this.frameArrayLength$.getValue()
        );
    }

    public getViewerOptionsMode() {
        if (window.innerWidth <= 979) {
            return "over";
        }
        return "side";
    }

    public getViewerOptionsBackdrop() {
        if (window.innerWidth <= 979) {
            return true;
        }

        return false;
    }

    public isLandscape(): boolean {
        return window.innerWidth > window.innerHeight;
    }



    private isIOS =
        navigator.userAgent.includes("Safari") &&
        navigator.userAgent.includes("Macintosh");

    private isIOSDevice: boolean = false;
    
    public get isMobile() {
        return window.innerWidth < 640;
    }

    public get isEdgeiOS() {
        return this.debug.isIosEdgeBrowser();
    }

    public isSunglass() {
        const f = this.selectedFrame$.getValue();
        if (f?.glassTypeEligibility) {
            return f?.glassTypeEligibility == GLASS_TYPE_ELIGIBILITY.SUN;
        } else {
            const suncheck = /(SUN$)|(SUN.(\d?))$/gm;
            return suncheck.test(f?.color);
        }
    }

    public isAvatarVisible(): boolean {
        return this.viewerFactory.createNewViewerWidget().isAvatarVisible();
    }

    // ################################# \\
    // ######## Viewer Section ######### \\
    // ################################# \\

    private async getAvatarEndpoints(): Promise<AvatarEndpoints> {
        try{
            const starttime2 = performance.now();

            const meshTask = this._datacache.getBinary(BinaryType.AVATAR, this.sessionId);
            const metadataTask = this._datacache.getBinary(BinaryType.AVATAR_METADATA, this.sessionId);
            const textureTask = this._datacache.getBinary(BinaryType.AVATAR_TEXTURE, this.sessionId);
            const result = [await meshTask, await metadataTask, await textureTask];

            const time2 = performance.now() - starttime2;

            this.debug.log('[Viewer] Time taken for loading avatar endpoints', time2);

            return {
                mesh: {
                    mesh: result[0],
                    fileFormat: "obj"
                },
                metadata: result[1],
                texture: result[2]
            } as AvatarEndpoints;
        } catch(error) {
            this.isLoading = false;
            throw error;
        }
    }

    public async initializeViewerWithoutFrame() {
        const startTime = performance.now();

        const options = this.viewerFactory.createNewViewerInitOptions(
            "viewer-wrapper",
            this.isDebugMode,
            await this.avatarAssets as AvatarEndpoints
        );

        const resumeOptions =
            this.viewerFactory.createNewViewerInitOptions("viewer-wrapper");

        resumeOptions.glasses = null;
        resumeOptions.lensSettings = null;
        resumeOptions.glassesTransformation = null;

        if (
            this._session.lastUsedSession$.getValue() == null ||
            this._session.lastUsedSession$.getValue() !== this.sessionId
        ) {
            resumeOptions.avatar = await this.avatarAssets as AvatarEndpoints;
            this._session.lastUsedSession$.next(this.sessionId);
            this.debug.log(
                "[VIEWER] Changed Avatar because of other session..."
            );
        }

        this.viewerFactory
            .createNewViewerWidget()
            .resume(resumeOptions)
            .then((r) => {
                this.debug.log(
                    `[Viewer Page] Resumed Viewer succesfully => OK`
                );

                if (window.innerWidth >= 601) {
                    this.viewerOptions.open();
                }

                this.isLoading = false;
                this.initalLoading = false;
                this.viewerFactory.isPaused$.next(false);
                const time = Math.floor(performance.now() - startTime);
                this.appInsight.logMetric("viewer_initialization", time);
                this.debug.log(
                    `[Viewer] Initialization resume of viewer took ${time}ms (${time / 1000
                    }s)`
                );
            })
            .catch((e) => {
                this.viewerFactory
                    .createNewViewerWidget()
                    .initialize(options)
                    .then(() => {
                        this.debug.log(
                            `[Viewer Page] Viewer succesfully => OK`
                        );

                        if (window.innerWidth >= 601) {
                            this.viewerOptions.open();
                        }

                        this.isLoading = false;
                        this.initalLoading = false;
                        const time = Math.floor(performance.now() - startTime);
                        this.appInsight.logMetric(
                            "viewer_initialization",
                            time
                        );
                        this.debug.log(
                            `[Viewer] Initialization of viewer took ${time}ms (${time / 1000
                            }s)`
                        );
                        this.viewerFactory.isInitialized$.next(true);
                    }, error => {
                        this.debug.log(
                            `[VIEWER] Error has occured while trying to initialize VTO instance. Error: ${ error }`
                        );
                    });
            });
    }

    public async initializeViewerWithFrame(queryFrameId) {
        const startTime = performance.now();
        this.isLoading = true;

        const resumeOptions =
            this.viewerFactory.createNewViewerInitOptions("viewer-wrapper");

        const initOptions = this.viewerFactory.createNewViewerInitOptions(
            "viewer-wrapper",
            false,
            await this.avatarAssets as AvatarEndpoints
        );

        const glassOptTask = this.viewerFactory.createDetailedGlassOptionsParallel(queryFrameId);

        const transformationTask = this.getTransformationMatrix(queryFrameId);

        const response = [await glassOptTask, await transformationTask];

        if (response[1] != null) {
            resumeOptions.glasses = response[0] as GlassesEndpoints;
            resumeOptions.glassesTransformation = response[1] as GlassesTransformation;

            initOptions.glasses = response[0] as GlassesEndpoints;
            initOptions.glassesTransformation = response[1] as GlassesTransformation;
        } else {
            resumeOptions.glasses = null;
            resumeOptions.glassesTransformation = null;

            initOptions.glasses = null;
            initOptions.glassesTransformation = null;
        }

        if (
            this._session.lastUsedSession$.getValue() == null ||
            this._session.lastUsedSession$.getValue() !== this.sessionId
        ) {
            resumeOptions.avatar = await this.avatarAssets as AvatarEndpoints;
            this._session.lastUsedSession$.next(this.sessionId);
            this.debug.log(
                "[VIEWER] Changed Avatar because of other session..."
            );
        }

        this.viewerFactory
            .createNewViewerWidget()
            .resume(resumeOptions)
            .then(async () => {
                this.viewerFactory
                    .createNewViewerWidget()
                    .setCameraSettings(
                        this.viewerFactory.createCameraSettings(),
                        false
                    );
                this.debug.log(`[Viewer Page] Loaded Viewer succesfully => OK`);
                this.isLoading = false;
                this.initalLoading = false;
                this.viewerFactory.isPaused$.next(false);
                await this.setLensforViewer(queryFrameId);

                if (window.innerWidth >= 601 && this.fromQueryFlag === 'recommendation') {
                    this.viewerOptions.open();
                }

                const time = Math.floor(performance.now() - startTime);

                this.debug.log(
                    `[Viewer] Resumed Initialization of viewer with frame took ${time}ms (${time / 1000
                    }s)`
                );
            })
            .catch(async (err) => {
                try {
                    await this.viewerFactory
                    .createNewViewerWidget()
                    .initialize(initOptions);
                }
                catch(error) {
                    this.debug.log(
                        `[VIEWER] Error has occured while trying to initialize VTO instance. Error: ${ error }`
                    );
                    return;
                }

                this.viewerFactory
                    .createNewViewerWidget()
                    .setCameraSettings(
                        this.viewerFactory.createCameraSettings(),
                        false
                    );
                this.debug.log(`[Viewer Page] Loaded Viewer succesfully => OK`);
                this.isLoading = false;
                this.initalLoading = false;
                await this.setLensforViewer(queryFrameId);

                if (window.innerWidth >= 601 && this.fromQueryFlag === 'recommendation') {
                    this.viewerOptions.open();
                }

                const time = Math.floor(performance.now() - startTime);

                this.debug.log(
                    `[Viewer] Initialization of viewer with frame took ${time}ms (${time / 1000
                    }s)`
                );

                this.viewerFactory.isInitialized$.next(true);
            });
    }

     async setLensforViewer(queryFrameId) {
        if (this.selectedCoating || this.selectedTint){
            if (this.selectedCoating) {
                this.currentLensSettingsCatalogCode = this.selectedCoating.catalogCode;
                this.selectedCoating$.next(this.selectedCoating.catalogCode);
                const ls = Object.assign({}, this._tintsCoatings.getlensSettingsByCatalogCode(this.selectedCoating.catalogCode));
                this.viewerFactory.createNewViewerWidget().setLensSettings(ls);
                this.currentLensSettings = ls;
            }
                
            if(this.selectedTint) {
                const ls = this.mergeTintAndCoating(this.selectedTint);
                this.currentLensSettings = ls;
                this.viewerFactory.createNewViewerWidget().setLensSettings(ls);
            }
             
        }
        else if (this.isSunglass()) {
            try {
            await this._frames
                .sunglassShader(queryFrameId)
                .toPromise()
                .then(async () => await this.resetSunglass());
            }
                catch {
                    this.currentLensSettings = Object.assign(
                        {},
                        this._tintsCoatings.getlensSettingsByCatalogCode(
                            this.duravisionPlatinumId
                        )
                    );
                }
        } else {
            this.currentLensSettings = Object.assign(
                {},
                this._tintsCoatings.getlensSettingsByCatalogCode(this.duravisionPlatinumId)
            );
        }
    }
   
    public selectCoating(catalogCode: string) {
        if (this.selectedFrame$.getValue() && !this.frameSizeError) {
            const coatingObj = this._tintsCoatings.getLensByCatalogCode(catalogCode);

            if (this.currentLensSettingsCatalogCode === coatingObj.catalogCode) {
                if(!this.isSunglass()){
                    this.resetCoating();
                }else{
                    if (!this.selectedTint) {
                        this.resetSunglass();
                    } else {
                        this.resetCoatingToInitialValue();
                    }
                }
            } else {
                this.currentLensSettingsCatalogCode = coatingObj.catalogCode;
                this.selectedCoating$.next(coatingObj.catalogCode);
                this.selectedCoating = this._tintsCoatings.getLensByCatalogCode(coatingObj.catalogCode);
                this.toggleCoatingOnSelectedFrame();
                
                const ls = Object.assign({}, this._tintsCoatings.getlensSettingsByCatalogCode(coatingObj.catalogCode));
                
                const lenSettings = this.setTintsforCoating(ls,coatingObj);
                this.viewerFactory.createNewViewerWidget().setLensSettings(lenSettings);
                this.currentLensSettings = lenSettings;
            }
            this.closeViewerIfMobile();
            this.stats.log({type: InteractionType.TC_TRY_ON, userId: this._session.selectedSession$.getValue()?.consumerId, sessionId: this.sessionId, coatingId: catalogCode, tintId: null, createdAt: new Date(Date.now()), frameId: this.selectedFrame$.getValue()?.id} as TintCoatingInteraction);
        } else {
            this.debug.log(
                "you need to select a frame before selecting a coating"
            );
        }
    }

    setTintsforCoating(lensSettings, coatingObj): LensSettings {
        let settings = null;
        // Clear the Tint if its restricted by the selected coating
        if (this.currentTintCatalogCode) {
            const t = this._tintsCoatings.getTintByCatalogCode(this.currentTintCatalogCode);
            if (this.hasTintRestrictions(t)) {
                this.resetTint();
                if (this.selectedCoating?.defaultTint)
                    this.selectTints(this.selectedCoating.defaultTint);
            } else {
                this.currentLensSettings = lensSettings;
                settings = this.mergeTintAndCoating(this.selectedTint);
            }
        } else {
            if (coatingObj.defaultTint) {
                this.selectTints(coatingObj.defaultTint);
            }
        }
        return settings ? settings : lensSettings;
    }


    public async resetCoating() {
        if (this.selectedFrame$.getValue() == null) {
            return;
        }

        if (this.isSunglass() && !this.selectedTint) {
            await this.resetSunglass();
            return;
        }

        this.resetCoatingToInitialValue();
    }

    resetCoatingToInitialValue() {
        const ls = Object.assign({}, this._tintsCoatings.getlensSettingsByCatalogCode(this.duravisionPlatinumId));
        this.currentLensSettingsCatalogCode = null;
        this.selectedCoating$.next(null)
        this.selectedCoating = null;
        this.toggleCoatingOnSelectedFrame();
        this.currentLensSettings = ls;
        let settings = null;
        if (this.selectedTint) settings = this.mergeTintAndCoating(this.selectedTint);

        const lenSettings = settings ? settings : ls;
        this.currentLensSettings = lenSettings;
        this.viewerFactory.createNewViewerWidget().setLensSettings(lenSettings);

        this.highlightCoatingMenu(null);
    }

    private toggleCoatingOnSelectedFrame() {
        const frame = this.selectedFrame$.getValue();
        const frameObj = Object.assign({}, frame);
        frameObj.coating = this.selectedCoating ? this.selectedCoating : null;
        this.selectedFrame$.next(frameObj);
    }

    private toggleTintOnSelectedFrame() {
        const frame = this.selectedFrame$.getValue();
        const frameObj = Object.assign({}, frame);
        frameObj.tint = this.selectedTint ? this.selectedTint : null;
        this.selectedFrame$.next(frameObj);
    }

    public async resetTint(lensSettingsId?: string) {
        if (this.selectedFrame$.getValue() == null) {
            return;
        }
        if (this.isSunglass() && !this.selectedCoating) {
            await this.resetSunglass();
            return;
        }

        const ls = Object.assign(
            {},
            this._tintsCoatings.getlensSettingsByCatalogCode(lensSettingsId ? lensSettingsId : this.currentLensSettingsCatalogCode ? this.currentLensSettingsCatalogCode : this.duravisionPlatinumId)
        );
        this.currentLensSettings = ls;
        this.currentTintCatalogCode = null;
        this.selectedTint$.next(null);
        this.selectedTint = null;
        this.toggleTintOnSelectedFrame();
        this.viewerFactory.createNewViewerWidget().setLensSettings(ls);

        this.highlightTintMenu(null);
        this.sunSliderFocus = false;
    }

    public async resetSunglass() {
        try{
        await this._frames
            .sunglassShader(this.selectedFrame$.getValue().id)
            .toPromise()
            .then((lensSettings) => {
                this.viewerFactory
                    .createNewViewerWidget()
                    .setLensSettings(lensSettings);

                this.resetTintAndCoatingSelection(lensSettings);
            });
        }
        catch {
            this.currentLensSettings = Object.assign(
                {},
                this._tintsCoatings.getlensSettingsByCatalogCode(
                    this.duravisionPlatinumId
                )
            );
        }
        return;
    }

    resetTintAndCoatingSelection(lensSettings) {
        this.currentLensSettings = lensSettings;
        this.currentLensSettingsCatalogCode = null;
        this.selectedCoating$.next(null);
        this.selectedCoating = null;
        this.currentTintCatalogCode = null;
        this.selectedTint$.next(null);
        this.selectedTint = null;
        this.toggleCoatingOnSelectedFrame();
        this.toggleTintOnSelectedFrame();

        this.highlightTintMenu(null);
        this.highlightCoatingMenu(this.currentLensSettingsCatalogCode);
        this.sunSliderFocus = false;
    }

    public selectTints(catalogCode: string) {
        if (this.selectedFrame$.getValue() && !this.frameSizeError) {
            const t = Object.assign({}, this._tintsCoatings.getTintByCatalogCode(catalogCode));
            if (t && !this.hasTintRestrictions(t) && !this.checkCoatingRequiresTint(catalogCode)) {
                this.sliderValue = 0;
                this.currentTintCatalogCode = catalogCode;
                this.selectedTint$.next(catalogCode);
                this.selectedTint = this._tintsCoatings.getTintByCatalogCode(catalogCode);

                this.toggleTintOnSelectedFrame();

                this.setSunsliderValuesFromTint(t);
                const ls = this.mergeTintAndCoating(t);
                this.currentLensSettings = ls;
                this.viewerFactory.createNewViewerWidget().setLensSettings(ls);

                if (window.innerWidth <= 979) {
                    this.viewerOptions.close();
                }

                this.stats.log({userId: this._session.selectedSession$.getValue()?.consumerId, sessionId: this.sessionId, tintId: catalogCode, coatingId: null, frameId: this.selectedFrame$.getValue()?.id, type: InteractionType.TC_TRY_ON, createdAt: new Date(Date.now())} as TintCoatingInteraction);

                this.sunSliderFocus = (((this._tintsCoatings.isPhotoFusionType(t)) && !this._tintsCoatings.isUniColorType(t)) || (this._tintsCoatings.isAdaptiveSunGradientType(t)));
                this.highlightTintMenu(catalogCode); // Need to be the last action, otherwise the deselect will not work!
            }
        } else {
            this.debug.log(
                "You need to select a frame before selecting a tint"
            );
        }
    }

    // checks if selecting tint is mandatory for coating or not.
    checkCoatingRequiresTint(catalogCode: string): boolean {
        // check if coatings is selected or not.
        if(this.selectedCoating) {
            /**
             *  if a tint selected and selected coating has defaultTint then we should not allow de-selection of tint.
             *  otherwise, we can allow de-selection of tint.
             *  */
            if (this.selectedTint && this.selectedTint?.catalogCode === catalogCode) {
                if (this.selectedCoating?.defaultTint) return true;
            }
        }

        // if none of the above conditions are met then return false.
        return false;
    }

    public hasTintRestrictions(tint: TintObj): boolean {
        if (this.currentLensSettingsCatalogCode) {
            const t = this._tintsCoatings.getLensByCatalogCode(this.currentLensSettingsCatalogCode);
            if (t && t.tintRestrictions)
                return t.tintRestrictions?.includes(tint?.vcldTintGroupId);
            return false;    
        }

        if(!this.selectedFrame$.getValue()) return true;

        return false;
    }

    public isRestrictedCoating(coating: CoatingObj): boolean {
        return coating.tintRestrictions ? coating.tintRestrictions.includes(this.selectedTint?.vcldTintGroupId) : false; 
    }

    private setSunsliderValuesFromTint(tint: TintObj) {
        const low = 0;
        const high = 100;

        if (this.sunSlider) {
            this.sunSlider.min = low;
            this.sunSlider.max = high;
        }
    }

    public onSunSliderInputChange(event: Event) {
        let tint = this._tintsCoatings.getTintByCatalogCode(this.currentTintCatalogCode);
        const value = (event.target as HTMLInputElement).value as any;

        this.currentLensSettings.opacity = ((tint.settings.bottomColor.highestAbsorption - tint.settings.bottomColor.lowestAbsorption) / 100) * value + tint.settings.bottomColor.lowestAbsorption;
        this.currentLensSettings.opacityTop = ((tint.settings.topColor.highestAbsorption - tint.settings.topColor.lowestAbsorption) / 100) * value + tint.settings.topColor.lowestAbsorption;

        this.viewerFactory.createNewViewerWidget().setLensSettings(this.currentLensSettings);
    }

    public mergeTintAndCoating(t: TintObj) {
        let copiedLensSettings = {} as LensSettings;
        if (this.currentLensSettings) {
            copiedLensSettings = Object.assign({}, this.currentLensSettings);
        }
        else {
            copiedLensSettings = Object.assign({}, this._tintsCoatings.getlensSettingsByCatalogCode(this.duravisionPlatinumId));
        }

        const tt = t.settings.topColor;
        const tb = t.settings.bottomColor;

        copiedLensSettings.lensWeightTop = { r: tt.r, g: tt.g, b: tt.b };
        copiedLensSettings.lensWeight = { r: tb.r, g: tb.g, b: tb.b };

        copiedLensSettings.opacity = t.settings.bottomColor.lowestAbsorption;
        copiedLensSettings.opacityTop = t.settings.topColor.lowestAbsorption;
        return copiedLensSettings;
    }

    private highlightCoatingMenu(id: string) {
        const l = document.getElementsByClassName("selectedLens");
        for (let i = 0; i < l.length; i++) {
            const item = l.item(i);
            if (item.id === id) {
                const classes = document.getElementById(id).className;
                this.debug.log(classes);
                if (classes.includes("selectedLens")) {
                    id = null;
                    this.resetCoating();
                }
            }
            item.classList.remove("selectedLens");
        }

        if (id != null) {
            document.getElementById(id)?.classList.add("selectedLens");
        }
    }

    private highlightTintMenu(id: string) {
        const l = document.getElementsByClassName("selectedTint");

        for (let i = 0; i < l.length; i++) {
            const item = l.item(i);
            if (item.id === (this.tintIdPrefix + id)) {
                const classes = document.getElementById(this.tintIdPrefix + id).className;
                this.debug.log(classes);
                if (classes.includes("selectedTint")) {
                    id = null;
                    this.resetTint();
                }
            }
            item.classList.remove("selectedTint");
        }

        if (id != null) {
            document.getElementById(this.tintIdPrefix + id)?.classList.add("selectedTint");
        }
    }

    public viewerOptionsToggle() {
        this.viewerOptions.toggle();
    }

    public isCompareButtonDisabled(frameId: string) {
        const isInList =
            this.compareList.findIndex((id) => id === frameId) !== -1;
        const listFull = this.compareList.length >= 2;
        return !isInList && listFull;
    }

    private setCompareActiveStyle(frameId: string) {
        const element = document.getElementById(
            `cbtn_${frameId}`
        ) as HTMLElement;
        element.classList.add("invert");
    }
    private removeCompareActiveStyle(frameId: string) {
        const element = document.getElementById(
            `cbtn_${frameId}`
        ) as HTMLElement;
        element.classList.remove("invert");
    }

    public isSelected(frameId: string) {
        return this.compareList.findIndex((id) => id === frameId);
    }

    // ################################ \\
    // ######## Frame Section ######### \\
    // ################################ \\
    // public canDecrease(): Observable<boolean> {
    //     return this.frameCounter$.pipe(
    //         map((cnt) => cnt > 0),
    //         shareReplay(1)
    //     );
    // }

    public decreaseCounter(): void {
        const frameCount = this.frameCounter$.getValue();
        if (frameCount > 0) {
            this.frameCounter$.next(frameCount - this.frameAmount);
        }
    }

    // public canIncrease(): Observable<boolean> {
    //     return combineLatest([this.frameCounter$, this.availableFrames$]).pipe(
    //         map(([cnt, availableFrames]) => availableFrames - 6 > cnt),
    //         shareReplay(1)
    //     );
    // }

    public IncreaseCounter(): void {
        const frameCount = this.frameCounter$.getValue();
        this.frameCounter$.next(frameCount + this.frameAmount);
    }

    public async selectFrameVariantById(variantFrameId: string) {
        if (this.selectedFrame$.getValue().id !== variantFrameId) {
            this.isLoading = true;
            const frame = this._frames.allFrames$
                .getValue()
                .find((f) => f.id === variantFrameId);
            this.checkAndResetSelectedFrame();

            let frameCopy = Object.assign({}, frame);
            frameCopy.coating = this.selectedCoating;
            frameCopy.tint = this.selectedTint;
            this.selectedFrame$.next(frameCopy);

           const go = await this.viewerFactory.createDetailedGlassOptions(
                variantFrameId
            );
            const matrix = await this.getTransformationMatrix(variantFrameId);

            // get lens settings for selecte frame variant.
            const glassSettings = await this.loadLensSettings();

            // initialize viewer with FPS reponse data and lens settings.
            this.viewerFactory
                .createNewViewerWidget()
                .setGlasses(go, matrix, glassSettings)
                .then((_) => {
                    this.isLoading = false;
                });
        }
    }

    private async loadLensSettings(): Promise<LensSettings> {
        // get default lens settings.
        const defaultLensSettings = this._tintsCoatings.getlensSettingsByCatalogCode(this.duravisionPlatinumId);
        let glassSettings: LensSettings = defaultLensSettings;

        // if tint or coating is selected, viewer will load new frame with selected tint/coating.
        if (!this.selectedCoating && !this.selectedTint) {
            /**
             * if selected glass is 'sun' then load default-sunglass-settings
             * and deselect user selected tint/coating.
             *  */
            if (this.isSunglass()) {
                try {
                    glassSettings = await this._frames.sunglassShader(this.selectedFrame$.getValue().id).toPromise();
                    this.resetTintAndCoatingSelection(glassSettings);
                } catch(error) {
                    /**
                     * catch to handle some frames having 'sunglass' property set to true but no asset available.
                     * fall back to default lens settings.
                     * */
                    glassSettings = defaultLensSettings;
                }
            }

            // additional check for safe initialization of viewer.
            if (!glassSettings) {
                // glassSettings is null at his level then try to reload default settings from service level object. 
                glassSettings = this._tintsCoatings.getlensSettingsByCatalogCode(this.duravisionPlatinumId);
            }
        } else {
            // if user has selected tint/coating then use current lens settings.
            glassSettings = this.currentLensSettings;
        }

        return glassSettings;
    }

    public checkAndResetSelectedFrame() {
        const frameId = this.selectedFrame$.getValue()?.id;
        const subscriber = this._session.selectedSession$
        .pipe(
            first(),
            tap((session) => {
                const index = session.favoritedFrames.findIndex(
                    (e) => e.frameId === frameId && e.coatingId == this.selectedCoating?.catalogCode && e.tintId == this.selectedTint?.catalogCode
                );
                if (index === -1) {
                    this.resetSelectedFrame();
                }
            }),
            finalize(() => subscriber.unsubscribe())
        )
        .subscribe();
    }

    public async selectFrame(frame: Frame, isFavorite?: Boolean) {
        let isDefaultLensMissing:Boolean=false;
        this.highlightCoatingMenu(null);
        const selectedFrame = this.selectedFrame$.getValue();
        if (frame.id !== selectedFrame?.id 
            || frame.coating?.catalogCode != this.selectedCoating?.catalogCode 
            || frame.tint?.catalogCode != this.selectedTint?.catalogCode) {
            const start = performance.now();

            this.isLoading = true;
            this.selectedFrame$.next(frame);

            this.closeViewerIfMobile();
            performance.now();

            const glassOptTask = this.viewerFactory.createDetailedGlassOptions(
                frame.id
            );

            const transformationTask = this.getTransformationMatrix(frame.id);

            const response = [await glassOptTask, await transformationTask];

            const go = response[0] as GlassesEndpoints;
            const matrix = response[1] as GlassesTransformation;

            if (matrix == null) return;

            console.log('all assets', go);

            const time1 = performance.now() - start;
            console.log(`[Viewer] frame assets took ${time1}ms (${time1 / 1000}s)`);

            if (!this.currentLensSettings && !isFavorite) {
                this.selectCoating(this.duravisionPlatinumId);
            }

            if (this.isSunglass()) {
            try {
                let lensSettings = await this._frames
                    .sunglassShader(frame.id)
                    .toPromise();
                await this.resetSunglass();
            }
            catch {
                this.currentLensSettings = Object.assign(
                    {},
                    this._tintsCoatings.getlensSettingsByCatalogCode(
                        this.duravisionPlatinumId
                    )
                    
                );
                this.selectCoating(this.duravisionPlatinumId);
                isDefaultLensMissing=true;
            }
            } else {
                this.resetCoating();
                this.resetTint();
            }

            if (isFavorite && !isDefaultLensMissing) {
                if (frame.coating && frame.coating.catalogCode) {
                    this.selectCoating(frame.coating.catalogCode);
                }

                if (frame.tint && frame.tint.catalogCode) {
                    this.selectTints(frame.tint.catalogCode);
                }
            }

            this.viewerFactory
                .createNewViewerWidget()
                .setGlasses(go, matrix)
                .then((_) => {
                    this.isLoading = false;
                    const time = performance.now() - start;
                    this.debug.log(
                        `loading of frame took ${time}ms (${time / 1000}s)`
                    );
                    this.closeViewerIfMobile();
                })
                .catch((error) => {
                    this.debug.log(error);                   
                    this._errorHandler.handleError(error);
                });

                this.updateFrameAvailability(frame);
        }
    }

    private closeViewerIfMobile(): void {
        if (window.innerWidth <= 979) 
             this.viewerOptions.close();    
    }


    private updateFrameAvailability(frame: Frame): void {
         
         /*  Check if availability status of a frame is set
            If not the frame appears as permanently not available
         */
        if (frame.availabilityStatus) {
            this.isFrameAvailable = parseInt(FrameAvailability[frame.availabilityStatus]) == FrameAvailability.AVAILABLE;
            this.isFrameTemporaryUnavailable = parseInt(FrameAvailability[frame.availabilityStatus]) == FrameAvailability.TEMPORARY_NOT_AVAILABLE;
            this.isFramePermanentlyUnavailable = parseInt(FrameAvailability[frame.availabilityStatus]) == FrameAvailability.PERMANENTLY_NOT_AVAILABLE;
        } else {
            this.isFrameAvailable = false;
            this.isFrameTemporaryUnavailable = false;
            this.isFramePermanentlyUnavailable = true;
        }
    }

    public favoriteSliderFrame(frameId: string) {
        event.preventDefault();
        event.cancelBubble = true;

        this.gtm.pushEvent(GTMCustomEvents.viewer_slider_favourites);

        const subscriber = this._session.selectedSession$
            .pipe(
                first(),
                mergeMap((session) => {
                    const index = session.favoritedFrames.findIndex(
                        (e) => e.frameId === frameId
                    );
                    const favFrameListIndex=this._frames.listFavFramesSession?.findIndex(f=>f.frameId === frameId);
                    if (index === -1) {
                        const favtFrame = {frameId: frameId, tintId: null, coatingId: null, lastUpdate:new Date(Date.now())  } as FavoriteFrame;                       
                        this._frames.listFavFramesSession= [...this._frames.listFavFramesSession,favtFrame];
                        session.favoritedFrames = [...session.favoritedFrames,...this._frames.listFavFramesSession];
                    } else {
                        session.favoritedFrames.splice(index, 1);
                        if(favFrameListIndex !=-1)this._frames.listFavFramesSession?.splice(favFrameListIndex,1);    
                        session.favoritedFrames = [...session.favoritedFrames,... this._frames.listFavFramesSession]; 

                    }
                    let listFavFramesSession=JSON.parse(JSON.stringify(this._frames.listFavFramesSession));
                    return this._frames.updateSessionFavorites(
                        session.favoritedFrames,this.sessionId, listFavFramesSession
                    );
                }),
                finalize(() => subscriber.unsubscribe())
            )
            .subscribe();
    }

    // get object of type 'FavoriteFrame' with check on tint and coating 
    private getFavoriteFrame(frameId: string): FavoriteFrame {
        // initial favorite object.
        let favoritedFrame = { frameId:frameId, coatingId: null, tintId: null, lastUpdate:new Date(Date.now()) } as FavoriteFrame;

        // assign tint value tint and coating value to favorite frame object if frameId and selected frameId are same.
        if (this.currentLensSettingsCatalogCode && this.isSelectedFrame(frameId))
            favoritedFrame.coatingId = this.currentLensSettingsCatalogCode;

        if (this.currentTintCatalogCode && this.isSelectedFrame(frameId))
            favoritedFrame.tintId = this.currentTintCatalogCode;

        //Disable saving tints and coatings for Cameron avatar based on feature flag as Cameron is yet to support T & C
        if(!this.features.isFeatureFlagEnabled('CameronTCEnabled') && this._session.selectedSession$.getValue().source === AvatarSource.CAMERON ){
            favoritedFrame.coatingId = null;
            favoritedFrame.tintId = null;
        } 

        // return constructed object
        return favoritedFrame;
    }

    // check if frameId is same as frameId used for viewer instance.
    private isSelectedFrame(frameId: string): Boolean {
        return this.selectedFrame$.getValue()?.id === frameId;
    }

    public getRecommendedFrameImage(frameRecommendation) {
        return this._frames.getFrameThumbnail(frameRecommendation) || of(null);
    }

    private async getTransformationMatrix(
        frameId: string
    ): Promise<GlassesTransformation> {
        const transformation = await this._frames
            .getFrameTransformations(frameId, this.sessionId)
            .toPromise();

        if (transformation['grpcError']) {
            if (transformation['grpcError'] === 'frame size is bigger than the avatar') {
                this.frameSizeError = true;
                this.isLoading = false;
                this.openFrameSizeError();
                let result: GlassesTransformation = null;
                return Promise.resolve(result);;
            }
        } else {
            this.frameSizeError = false;
        }

        this.sliderValueVertical = transformation.bestFit.verticalPosition;
        this.sliderValueHorizontal = transformation.bestFit.position == 0 ? 5 : transformation.bestFit.position;

        const appliedTransformation = transformation.verticalPositions[this.sliderValueVertical].positions[this.sliderValueHorizontal];
        const matrix = this.convertTo4x4(appliedTransformation);
        this.sliderTransformation = transformation;

        return matrix;
    }

    private openFrameSizeError() {
        this.snackbar.openFromComponent(SnackbarComponent, {
            data: {
              icon: 'info',
              heading: "errors.sorry",
              message: "pages.viewer.frame_size_error",
              btnIcon: 'close',
              support: false
            },
            panelClass: 'error-panel',
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 3000
          });
    }


    private convertTo4x4(obj) {
        const transformation = obj.transformation;
        const leftPad = obj.leftPadTransformation;
        const rightPad = obj.rightPadTransformation;

        const m: GlassesTransformation = {
            frame: [
                transformation.a11,
                transformation.a21,
                transformation.a31,
                transformation.a41,
                transformation.a12,
                transformation.a22,
                transformation.a32,
                transformation.a42,
                transformation.a13,
                transformation.a23,
                transformation.a33,
                transformation.a43,
                transformation.a14,
                transformation.a24,
                transformation.a34,
                transformation.a44,
            ],
            padLeft:
                leftPad == null
                    ? null
                    : [
                        leftPad.a11,
                        leftPad.a21,
                        leftPad.a31,
                        leftPad.a41,
                        leftPad.a12,
                        leftPad.a22,
                        leftPad.a32,
                        leftPad.a42,
                        leftPad.a13,
                        leftPad.a23,
                        leftPad.a33,
                        leftPad.a43,
                        leftPad.a14,
                        leftPad.a24,
                        leftPad.a34,
                        leftPad.a44,
                    ],
            padRight:
                rightPad == null
                    ? null
                    : [
                        rightPad.a11,
                        rightPad.a21,
                        rightPad.a31,
                        rightPad.a41,
                        rightPad.a12,
                        rightPad.a22,
                        rightPad.a32,
                        rightPad.a42,
                        rightPad.a13,
                        rightPad.a23,
                        rightPad.a33,
                        rightPad.a43,
                        rightPad.a14,
                        rightPad.a24,
                        rightPad.a34,
                        rightPad.a44,
                    ],
        };

        return m;
    }


    public onValueChange(data) {
        const event = data.event as number | ChangeContext;
        const direction = data.direction as 'vertical' | 'horizontal';

        const value = event;

        if(event instanceof ChangeContext) {
            const v = event.value;
            this.sliderValueVertical = v;
            return;
        }

        if(direction === 'vertical') {
            this.sliderValueVertical = value as number;
        } else {
            this.sliderValueHorizontal = value as number;
        }
    }

    public onInputChange(data) {
        const event = data.event as ChangeContext;
        const direction = data.direction as 'vertical' | 'horizontal';
        const value = event.value;     
        const transformation = this.sliderTransformation;
        let appliedTransformation = null;

        // Nose Position
        if (direction === 'vertical') {
            const positions = transformation.verticalPositions[value].positions;
            appliedTransformation = positions[this.sliderValueHorizontal];
        }

        // Frame Rotation
        if (direction === 'horizontal') {
            const positions = transformation.verticalPositions[this.sliderValueVertical].positions;
            appliedTransformation = positions[value];
        }

        if (appliedTransformation === null) return;

        const m = this.convertTo4x4(appliedTransformation);

        this.viewerFactory.createNewViewerWidget().setGlassesTransformation(m);
    }

    public onScroll() {
        const s = this._frameWrapperContainer.scrollTop;

        if (s % this.scrollPin < this.scrollPinThreshold) {
            this.debug.log(
                `[Gallery Page] [Scroll] Index scrolled to ${s} -> Loading ${this.preloadIncrement} more frames.`
            );
        }
    }

    // ################################ \\
    // ##### VTO Control Section ###### \\
    // ################################ \\

    public async closeViewer() {
        await this.screenshotFromAngel(0.2)        
            if (!this.viewerFactory.isPaused$.getValue()) {               
                this.viewerFactory.createNewViewerWidget().pause();                
                this.viewerFactory.isPaused$.next(true);
            }

            if (this.route.snapshot.queryParams.from == "gallery") {
                this.router.navigate([`gallery/${this.sessionId}`], {
                    queryParams: {
                        from: "profile",
                        mode: this.route.snapshot.queryParams.mode,
                        scrollPosition:this.route.snapshot.queryParams.scrollPosition,
                        endIndex:this.route.snapshot.queryParams.endIndex,
                        dmt:this.route.snapshot.queryParams.dmt,
                        list: this.route.snapshot.queryParams.list
                    },
                });
            } else {
                this.router.navigate([`profile`]);
            }
    }

    public switchToGallery() {
        this.navigateToGallery(this.pageSource, this.route.snapshot.queryParams.mode);
    }

    public switchToFavoriteGallery() {
        this.navigateToGallery(this.pageSource, this.favoriteMode);
    }

    public switchToOpticianGallery() {
        this.navigateToGallery(this.pageSource, this.opticianMode);
    }

    navigateToGallery(from: string, mode: string) {
        this.screenshotFromAngel(0.2).then((_) => {
            if (!this.viewerFactory.isPaused$.getValue()) {
                this.viewerFactory.createNewViewerWidget().pause();
                this.viewerFactory.isPaused$.next(true);
            }
            this.router.navigate([`gallery/${this.sessionId}`], {
                queryParams: {
                    from,
                    mode
                },
            });
        });
    }
    public switchToOrder() {
        this.screenshotFromAngel(0.2).then((_) => {
            if (!this.viewerFactory.isPaused$.getValue()) {
                this.viewerFactory.createNewViewerWidget().pause();
                this.viewerFactory.isPaused$.next(true);
            }

            const subscriber = this._session.selectedSession$
            .pipe(
                first(),
                tap((session) => {
                    const frameId = this.selectedFrame$.getValue().id;
                    const index = this.getFavoriteFrameIndex(session, frameId);
                    const selectedFrame = this.selectedFrame$.getValue();

                    let orderPageParams = {
                        from: "viewer",
                        tint: selectedFrame.tint?.catalogCode,
                        coating: selectedFrame.coating?.catalogCode
                    };

                    if (index === -1) {
                        this.resetSelectedFrame();
                    }

                    this.router.navigate(
                        [
                            `order/${this.sessionId}/${selectedFrame.id
                            }`,
                        ],
                        { queryParams: orderPageParams }
                    );
                }),
                finalize(() => subscriber.unsubscribe())
            )
            .subscribe();
        });
    }

    public switchToCompare(compareFrameList: string[]) {
        this.debug.log(
            `%c[Switch => Compare] %cCurrent Viewer state is %c${this.viewerFactory.isPaused$.getValue()}`,
            "font-weight: bold; color: black;",
            "",
            "font-style: italic; color: green;"
        );
        if (!this.viewerFactory.isPaused$.getValue()) {
            this.viewerFactory.createNewViewerWidget().pause();
            this.viewerFactory.isPaused$.next(true);
        }

        const subscriber = this._session.selectedSession$
        .pipe(
            first(),
            tap((session) => {
                const selectedFrame = this.selectedFrame$.getValue();

                let comparePageParams = {
                    from: "viewer",
                    leftCoating: selectedFrame.coating?.catalogCode,
                    leftTint: selectedFrame.tint?.catalogCode,
                    rightCoating: compareFrameList[2],
                    rightTint: compareFrameList[1]
                };

                const secondFrame = compareFrameList[0];

                const index = this.getFavoriteFrameIndex(session, selectedFrame.id);

                if (index === -1) {
                    this.resetSelectedFrame();
                }

                const url = `/compare/${this.sessionId}/${selectedFrame.id}/${secondFrame}`;
                this.router.navigate([url], {
                    queryParams: comparePageParams
                });

            }),
            finalize(() => subscriber.unsubscribe())
        )
        .subscribe();
    }

    getFavoriteFrameIndex(session: AvatarCreationSession, frameId: string) {
        const favtFrame = this.getFavoriteFrame(frameId);

        return session.favoritedFrames.findIndex(
            (e) => (e.frameId === favtFrame.frameId && e.coatingId == favtFrame.coatingId && e.tintId == favtFrame.tintId)
        );
    }

    resetSelectedFrame() {
        const selectedFrame = this.selectedFrame$.getValue();
        const frameObj = Object.assign({}, selectedFrame);
        frameObj.tint =  null;
        frameObj.coating =  null;
        this.selectedFrame$.next(frameObj);
    }

    public toggleFullscreen() {
        const elements = document.getElementsByClassName("hideable");

        if ((elements.item(0) as HTMLElement).style.display !== "none") {
            document.getElementById("viewer-wrapper").style.height =
                "calc(calc(100vh - 64px))";
            this.fullsreenIcon = "close_fullscreen";
        } else {
            document.getElementById("viewer-wrapper").style.height =
                "calc(calc(100vh - 64px - 32px - 128px - 16px))";
            this.fullsreenIcon = "open_in_full";
        }

        for (let i = 0; i < elements.length; i++) {
            const el = elements.item(i) as HTMLElement;
            el.style.display = el.style.display === "none" ? "block" : "none";
        }
    }

    public toggleAvatarVisibility() {
        this.gtm.pushEvent(GTMCustomEvents.viewer_frame_only);
        const temp = this.viewerFactory
            .createNewViewerWidget()
            .isAvatarVisible();
        this.viewerFactory.createNewViewerWidget().setAvatarVisible(!temp);
    }

    public toggleFrameSlider(slider: boolean): void {
        const favoritesSelected = slider;
        if (this.favoritesSelected.getValue() !== favoritesSelected) {
            this.frameCounter$.next(0);
            this.favoritesSelected.next(favoritesSelected);
        }
    }

    public favoriteSelectedFrame() {
        this.gtm.pushEvent(GTMCustomEvents.viewer_favourite_marker_bottom);
        const subscriber = this._session.selectedSession$
            .pipe(
                first(),
                mergeMap((session) => {
                    const frameId = this.selectedFrame$.getValue().id;
                    const favtFrame = this.getFavoriteFrame(frameId);                    
                    
                    const index = session.favoritedFrames.findIndex(
                        (e) => (e.frameId === favtFrame.frameId && e.coatingId == favtFrame.coatingId && e.tintId == favtFrame.tintId)                      
                    );
                    const favFrameListIndex=this._frames.listFavFramesSession?.findIndex(f=>f.frameId === favtFrame.frameId && f.coatingId == favtFrame.coatingId && f.tintId == favtFrame.tintId);
                    if (index === -1) {
                        this._frames.listFavFramesSession= [...this._frames.listFavFramesSession,favtFrame];
                        session.favoritedFrames = [...session.favoritedFrames,... this._frames.listFavFramesSession];
                        this.stats.log({type: InteractionType.TC_FAVORITE, coatingId: favtFrame.coatingId, tintId: favtFrame.tintId, frameId: favtFrame.frameId, createdAt: new Date(Date.now()), sessionId: this.sessionId, userId: this._session.selectedSession$.getValue()?.consumerId} as TintCoatingInteraction);
                    } else {
                        session.favoritedFrames.splice(index, 1);  
                        if(favFrameListIndex !=-1)this._frames.listFavFramesSession?.splice(favFrameListIndex,1);    
                        session.favoritedFrames = [...session.favoritedFrames,... this._frames.listFavFramesSession]; 
                    }
                    let listFavFramesSession=JSON.parse(JSON.stringify(this._frames.listFavFramesSession));
                    const result = this._frames.updateSessionFavorites(
                        session.favoritedFrames,this.sessionId, listFavFramesSession
                    );

                    return result;
                }),
                finalize(() => subscriber.unsubscribe())
            )
            .subscribe();
    }

    private async screenshotFromAngel(quality: number = 0.8) {
        const n = [
            [0.93938, -0.3428, -0.001622],
            [-0.003197, -0.003, -1],
            [0.3, 0.934, -0.004],
        ];

        let multiplier = 2.3;
        let t = { x: -0.11 * multiplier, y: -0.326 * multiplier, z: 0 };

        // Cameron
        if(this._session.selectedSession$.getValue().source === AvatarSource.CAMERON) {
            if (this.viewerFactory.createNewViewerWidget().isAvatarVisible()) {
                multiplier = 1.0;
                t = { x: -0.11 * multiplier, y: -0.326 * multiplier, z: 0.04 };
            }
            else{
                multiplier = 2.5;
                t = { x: -0.12 * multiplier, y: -0.350 * multiplier, z: 0.05 };
            }
        }



        const a: CameraView = { rotation: n, translation: t };

        if (!this.viewerFactory.createNewViewerWidget().isAvatarVisible()) {
            this.viewerFactory.createNewViewerWidget().setAvatarVisible(true);
            if(this._session.selectedSession$.getValue().source === AvatarSource.CAMERON){
                await this.viewerFactory
                .createNewViewerWidget()
                .takeScreenshot(a);
            }
        }

        if (true) {
            const image = await this.viewerFactory
                .createNewViewerWidget()
                .takeScreenshot(a);
            return this.getBlobFromImageData(image, quality);
        }
    }

    private getBlobFromImageData(image: ImageData, quality): Promise<void> {
        return new Promise((resolve) => {
            try {
                const { width, height } = image;

                const canvas = this.renderer.createElement("canvas");

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext("2d");
                ctx.putImageData(image, 0, 0);

                canvas.toBlob(
                    async (blob) => {
                        const blobUrl = URL.createObjectURL(blob);
                        await this._datacache.setCacheEntryAsync(
                            this.sessionId,
                            BinaryType[BinaryType.AVATAR_THUMBNAIL],
                            blobUrl
                        );
                        await this._datacache
                            .setAvatarThumbnail(this.sessionId, blob)
                            .toPromise();
                        resolve(void 0);
                    },
                    "image/jpeg",
                    quality
                );
            } catch (error) {
                this.debug.log(error);
            } finally {
                resolve(void 0);
            }
        });
    }

    public isStandardSize(size: string) {
        return size == "SMALL" || size == "MEDIUM" || size == "LARGE";
    }

    private convertToBlobUrl(image: ImageData) {
        return new Promise<string>((resolve, reject) => {
            try {
                const { width, height } = image;

                const canvas = this.renderer.createElement("canvas");

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext("2d");
                ctx.putImageData(image, 0, 0);

                canvas.toBlob(async (blob) => {
                    const blobUrl = URL.createObjectURL(blob);
                    resolve(blobUrl);
                });
            } catch (error) {
                this.debug.log(error);
                reject(error);
            }
        });
    }

    private convertToBlob(image: ImageData) {

        const dynamicRatio = window.devicePixelRatio;
        let scaleRatio = (dynamicRatio > 1)&&(window.innerWidth< 601) ? dynamicRatio/5 : 1;
        
        return new Promise<Blob>(async (resolve, reject) => {
            try {
                const { width, height } = image;
                const zeissLogoSize = 100 * scaleRatio;
                const margin = 48 * scaleRatio;
                const canvas = this.renderer.createElement("canvas");
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.scale(dynamicRatio, dynamicRatio);
                const scaledWidth = width/dynamicRatio;
                const scaledHeight = height/dynamicRatio;
                ctx.putImageData(image, 0, 0);
                const zeissLogo = "../../../../assets/images/zeiss-logo.svg";
                const zeissLogoElement = await this.loadCanvasImage(zeissLogo);
                ctx.drawImage(zeissLogoElement, (scaledWidth - margin - zeissLogoSize), margin, zeissLogoSize, zeissLogoSize);

                canvasTxt.font = "ZEISSFrutigerNextW1G-Reg";
                canvasTxt.align = 'left';
                canvasTxt.vAlign = 'top';

                const ecpLogo = await this._datacache.getBinary( BinaryType.ECP_LOGO, this._session.selectedSession$.getValue().opticianId,true);
                let ecpLogoHeight = 0;
                if(ecpLogo) {
                    const ecpLogoElement = await this.loadCanvasImage(ecpLogo);
                    ecpLogoHeight = ecpLogoElement.height;
                    let ecpLogoWidth = ecpLogoElement.width;
                    const logoBoxHeight = dynamicRatio===1 ? scaledHeight * 0.2 : scaledHeight * 0.1;
                    const logoBoxWidth = scaledWidth-margin-margin;
                    if(ecpLogoWidth > logoBoxWidth || ecpLogoHeight > logoBoxHeight){
                        let dimensions = this.calculateLogoDimensions(ecpLogoWidth, ecpLogoHeight, logoBoxWidth, logoBoxHeight);
                        ecpLogoElement.height = dimensions[1];
                        ecpLogoHeight = dimensions[1];
                        ecpLogoElement.width = dimensions[0];
                        ecpLogoWidth = dimensions[0];
                    }
                    
                    ctx.drawImage(ecpLogoElement, margin, scaledHeight-ecpLogoHeight-margin, ecpLogoWidth, ecpLogoHeight);
                    canvasTxt.fontSize = 32 * scaleRatio;
                    canvasTxt.drawText(ctx, this.translate.instant('application.co_branding_caption'), margin, (scaledHeight-ecpLogoHeight-(42 * scaleRatio)-margin), scaledWidth, (32 * scaleRatio) );
                } 

                canvasTxt.fontSize = 48 * scaleRatio;
                canvasTxt.drawText(ctx, this.selectedFrame$?.getValue().brand, margin, margin, (scaledWidth-zeissLogoSize-margin-margin-margin), (scaledHeight-ecpLogoHeight-margin-margin-margin));

                canvas.toBlob(async (blob) => {
                    resolve(blob);
                });
            } catch (error) {
                this.debug.log(error);
                reject(error);
            }
        });
    }

    /* 
    ** calculates the dimensions of a Logo limited by a container keeping the aspect ratio of the logo
    ** Return an array with the calculated width and the calculated height 
    */
    public calculateLogoDimensions(originalWidth: number, originalHeight: number, containerWidth: number, containerHeight: number): number[] {
        
        const heightOverflowRatio = containerHeight/originalHeight;
        const widthOverflowRatio = containerWidth/originalWidth;

        const limiter = (heightOverflowRatio > widthOverflowRatio) ? widthOverflowRatio : heightOverflowRatio;

        const calculatedWidth =  Math.round(originalWidth * limiter);
        const calculatedHeight = Math.round(originalHeight * limiter);

        return new Array (calculatedWidth, calculatedHeight);
    }

    public blobToFile(theBlob: Blob, fileName: string): File {
        let b: any = theBlob;
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.name = fileName;

        //Cast to a File() type
        return <File>theBlob;
    }

    public async shareFrame() {
        setTimeout(() => this.captureScreenshotAndShareFrame(), 400);
    }

    public captureScreenshotAndShareFrame(){
        this.viewerFactory.createNewViewerWidget().setAvatarVisible(true);

        this.isLoading = true;

        const start = performance.now();

        this.zone.runOutsideAngular(async () => {
            const start = performance.now();

            const rendered = await this.viewerFactory
                .createNewViewerWidget()
                .takeScreenshot();
            const blob = await this.convertToBlob(rendered);

            const file = new File([blob], "screenshot.png", {
                lastModified: Date.now(),
                type: "image/png",
            });

            const genImage = performance.now() - start;
            this.debug.log(
                `%c[Performance] Image generation took %c${genImage}ms`,
                "font-weight: bold; font-size: 14px; color: yellow;",
                "color: red;"
            );

            const title = this.translate.instant("sharing.title");
            const text = this.translate.instant("sharing.text");

            const data: ShareData & { files: File[] } = {
                files: [file],
                title,
                text,
            };

            this.dialog.open(SocialDialogComponent, {
                autoFocus: false,
                data,
                maxHeight: "90vh",
            });

            this.isLoading = false;
        });
    }
    
    // ################################ \\
    // ###### Lifecycle Section ####### \\
    // ################################ \\

    public onResize() {
        if (window.innerWidth >= 1800) {
            this.frameAmount = 5;
        } else if (window.innerWidth >= 1200) {
            this.frameAmount = 5;
        } else if (window.innerWidth >= 900) {
            this.frameAmount = 5;
        } else if (window.innerWidth >= 640) {
            this.frameAmount = 3;
        } else if (window.innerWidth >= 0) {
            this.frameAmount = 2;
        }
    }

    //

    public trackColorMenu() {
        this.gtm.pushEvent(GTMCustomEvents.viewer_color_options);
    }

    public adjustTooltip() {
        if (this.isIOSDevice) {
            const orientation = window.orientation;
            let isTabletScreen = false;

            if (orientation !== null && orientation !== undefined) {
                // 0 or 180 then you are in portrait mode
                if (orientation == 0 || orientation == 180) {
                    if (window.innerWidth >= 640 && window.innerWidth <= 1200) {
                        isTabletScreen = true;
                    }
                } else if (orientation == 90 || orientation == 270 || orientation == -90) {
                    // 90 or 270 then you are in landscape mode.
                    if (window.innerHeight >= 640 && window.innerHeight <= 1200) {
                        isTabletScreen = true;
                    }
                }
            }

            if (isTabletScreen) {
                this.hideColorMenuTooltip = true;
                this.hideSizeMenuTooltip = true;
            } else {
                this.hideColorMenuTooltip = false;
                this.hideSizeMenuTooltip = false;
            }
        }

    }

    public trackSizeMenu() {
        this.gtm.pushEvent(GTMCustomEvents.viewer_size_options);
    }

    public trackNoseSlider() {
        this.gtm.pushEvent(GTMCustomEvents.viewer_nose_slider);
    }

    public trackTabFavorite() {
        this.gtm.pushEvent(GTMCustomEvents.viewer_slider_favourites);
    }

    public trackTabReccomendations() {
        this.gtm.pushEvent(GTMCustomEvents.viewer_slider_frames);
    }

    public trackTabLenses() {
        this.gtm.pushEvent(GTMCustomEvents.viewer_slider_lenses);
    }

    public toggleFilterBar(): void {
        this.filterFocus = !this.filterFocus
        this.frameFilter.removeTemporaryFilter(this.sessionId);
    }

    public clearFilters() {
        this.frameFilter.clearFilters(this.sessionId);
        this.toggleFilterBar();
        this.scrollContainer?.scrollTo(0,0);
    }

    public applyFilters() {
        this.frameFilter.applyFilters(this.sessionId);
        this.toggleFilterBar();
        this.scrollContainer?.scrollTo(0,0);
    }

    public setTabIndex(e: MatTabChangeEvent) {
        this.selectedTabIndex= e.index;
        this.scrollContainer?.scrollTo(0, this.scrollDepth[this.selectedTabIndex]);
    }

    public saveScrollHeight() {
        this.scrollDepth[this.selectedTabIndex] = this.scrollContainer?.scrollTop;;
    }

    private async loadCanvasImage(imgSrc: string): Promise<HTMLImageElement> {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve(img);
            }
            img.src = imgSrc;
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }        
        if (this.viewerScrollSubscription) {
            this.viewerScrollSubscription.unsubscribe();
        }
    }
}
